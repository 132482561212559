import FormField from "../../../../../../../CommonComponents/FormField";
import InputSection from "../../InputSection/InputSection";
import {
  brandIndustryOptions,
  businessModelOptions,
  conversionGoalOptions,
  marketFocusOptions,
} from "./conts";
import ISO6391 from "iso-639-1";
import FormikTextField from "../../../../../../../CommonComponents/FormField/components/TextField/TextField";

const BusinessForm = () => {
  const languagesOptions = ISO6391.getAllNames();

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 40 }}>
      <InputSection
        title="Brand Name"
        required
        rowGap={10}
        children={
          <FormField
            type="input"
            name={`name`}
            variant="outlined"
            placeholder="Company"
            customizedStyling={{ width: "50%" }}
            errorMessage
          />
        }
      />
      <InputSection
        title="About your business"
        required
        description="Describe your business, how it functions and where it’s positioned."
        rowGap={10}
        children={
          <FormField
            type="input"
            name={`description`}
            variant="outlined"
            placeholder="Growth"
            customizedStyling={{ width: "90%" }}
            multiline
            errorMessage
          />
        }
      />
      <InputSection
        title="Conversion Goal"
        required
        description="Your goals from content and SEO (commercial expectation from reader)."
        rowGap={10}
        children={
          <FormField
            name="conversion_goals"
            type="dynamicChoiceChipsGroup"
            options={conversionGoalOptions}
            errorMessage
            multiSelect
          />
        }
      />
      <InputSection
        title="Brand Industry"
        required
        rowGap={10}
        children={
          <FormField
            name="industry"
            type="dynamicChoiceChipsGroup"
            options={brandIndustryOptions}
            errorMessage
          />
        }
      />
      <InputSection
        title="Business Model"
        required
        rowGap={10}
        children={
          <FormField
            name="business_model"
            type="choiceChipsGroup"
            options={businessModelOptions}
            errorMessage
          />
        }
      />
      <InputSection
        title="Market Focus"
        required
        rowGap={10}
        children={
          <FormField
            name="market_focus"
            type="choiceChipsGroup"
            options={marketFocusOptions}
            errorMessage
          />
        }
      />

      <InputSection
        title="Primary Language"
        required
        rowGap={10}
        children={
          <FormField
            name="primary_language"
            type="select"
            options={languagesOptions.map((lang) => ({
              label: lang,
              value: lang,
            }))}
            customizedStyling={{ width: "50%" }}
            errorMessage
          />
        }
      />
      <InputSection
        title="Brand Website"
        required
        rowGap={10}
        children={
          <FormikTextField
            name="website"
            placeholder="https://"
            width="90%"
            margin={false}
            errorMessage
          />
        }
      />
      <InputSection
        title="Unique Selling Proposition (USP)"
        description="What makes your product or service unique and valuable to your customers?"
        rowGap={10}
        children={
          <FormField
            type="input"
            name={`usp`}
            variant="outlined"
            customizedStyling={{ width: "90%" }}
            multiline
            errorMessage
          />
        }
      />
      <InputSection
        title="Features"
        required
        description="Please enter at least two key features of your company’s product or service, describing what it offers or how it works."
        rowGap={10}
        children={
          <FormField
            type="dynamicMultipleTextFields"
            name="features"
            variant="outlined"
            fullwidth
            multiline
            minCharacters={20}
            placeholder="Type here"
            requiredInputsCount={2}
            errorMessage
          />
        }
      />
      <InputSection
        title="Benefits"
        required
        description="Please enter at least two key benefits of your company’s product or service, explaining how these features solve problems or add value for your customers."
        rowGap={10}
        children={
          <FormField
            type="dynamicMultipleTextFields"
            name="benefits"
            variant="outlined"
            fullwidth
            multiline
            minCharacters={20}
            placeholder="Type here"
            requiredInputsCount={2}
            errorMessage
          />
        }
      />
    </div>
  );
};
export default BusinessForm;
