import React from "react";
const checkedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 25"
    fill="none"
  >
    <rect y="0.5" width="24" height="24" rx="2" fill="#C7F565" />
    <path
      d="M18.7 7.32353C18.3 6.89216 17.7 6.89216 17.3 7.32353L9.8 15.4118L6.7 12.0686C6.3 11.6373 5.7 11.6373 5.3 12.0686C4.9 12.5 4.9 13.1471 5.3 13.5784L9.1 17.6765C9.3 17.8922 9.5 18 9.8 18C10.1 18 10.3 17.8922 10.5 17.6765L18.7 8.83333C19.1 8.40196 19.1 7.7549 18.7 7.32353Z"
      fill="black"
    />
  </svg>
);

export default checkedIcon;
