import { Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  iconTypography: {
    fontSize: 14,
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    columnGap: 5,
    width: "fit-content",
  },
}));
const ErrorTypography = ({ errorMessage, hasBackground = true }) => {
  const classes = useStyles();

  return (
    <Typography
      sx={{
        backgroundColor: hasBackground ? "#FEF3F2" : "transparent",
        padding: "5px 12px",
        borderRadius: "25px",
        color: "#E9301C",
        fontWeight: 450,
      }}
      className={classes.iconTypography}
    >
      <ErrorOutlineOutlinedIcon style={{ fontSize: 18 }} />
      {errorMessage}
    </Typography>
  );
};
export default ErrorTypography;
