import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WorkspaceSetUpCard from "../Card/Card";

const WelcomeDNA = ({
  setCurrentStep,
  backButton = true,
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const brandId = localStorage.getItem("brandId");
  return (
    <WorkspaceSetUpCard
      title="Help us get to know your brand a little better..."
      handleBack={() => setCurrentStep(1)}
      handleNext={() => {
        onClose();
        window.location.href = `/dashboard/${brandId}/?openBrandDna=true`;
      }}
      nextButtonLabel="Complete Brand DNA"
      buttonFullWidth={false}
      backButton={backButton}
    >
      <Typography style={{ fontSize: 14, color: "#626C74" }}>
        Complete the following questions to train Goodie on how _workspace_
        operates, and what makes it unique.
      </Typography>
    </WorkspaceSetUpCard>
  );
};
export default WelcomeDNA;
