import { Grid } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import AverageLineCard from "./Components/AverageLineCard/AverageLineCard";
import BrandVisibilityScoreCard from "./Components/BrandVisibilityScoreCard/BrandVisibilityScoreCard";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import RadarChartCard from "../../components/RadarChartCard/RadarChartCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import StackedBarCard from "../../components/StackedBarCard/StackedBarCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";

const radarData = [
  {
    benchmark: "Brand Recognition",
    Addidas: 120,
    Nike: 110,
    Puma: 150,
  },
  {
    benchmark: "Market Share",
    Addidas: 98,
    Nike: 130,
    Puma: 150,
  },
  {
    benchmark: "Product Quality",
    Addidas: 86,
    Nike: 130,
    Puma: 150,
  },
  {
    benchmark: "Innovation",
    Addidas: 99,
    Nike: 100,
    Puma: 150,
  },
  {
    benchmark: "Sustainability",
    Addidas: 85,
    Nike: 90,
    Puma: 150,
  },
  {
    benchmark: "Endorsements",
    Addidas: 65,
    Nike: 85,
    Puma: 150,
  },
];

const Dashboard = () => {
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="AI Visibility Dashboard"
        description="Access in-depth metrics, trend analysis, and side-by-side comparisons, along with global and localized insights, to make data-driven decisions and optimize your brand strategy."
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={3.8}>
            <AverageLineCard
              icon={<VisibilityIcon color="#000000" />}
              title="Brand Visibility"
              tooltip="Displays your brand's visibility score over time across 3 major LLMs - ChatGpt, Gemini and Perplexity. The percentage change reflects week on week change in the visibility score.
              
            All scores are out of 100"
              index={0}
              widget_type="Visibility"
            />
          </Grid>
          <Grid item xs={3.8}>
            <AverageLineCard
              icon={<SentimentIcon color="#000000" />}
              title="Current Sentiment Analysis"
              tooltip="Shows the LLM's sentiment towards your brand. Brand's visibility score is calculated across 3 major LLMs - ChatGpt, Gemini and Perplexity. The percentage change reflects week on week change in the Sentiment score with higher scores indicating positive sentiment and lower scores indicating negative sentiment.
            
            All scores are out of 100"
              index={1}
              widget_type="Sentiment"
            />
          </Grid>{" "}
          <Grid item xs={3.8}>
            <BrandVisibilityScoreCard />
          </Grid>{" "}
        </Grid>

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={7.9}>
            <LineChartCard
              icon={<VisibilityIcon color="#000000" />}
              title="Brand Visibility"
              tooltip="Track your brand’s visibility over selected time intervals across specific LLM platforms like ChatGpt, Gemini, and Perplexity. Helps identify visibility trend over a period of time."
              hasCaledarSelect
              hasLLMselect
              height={345}
              chartHasXasix
              chartHasYaxis
              chartHeight={260}
              chartHasTooltip
              chartHasMarginTop={20}
              chartHasMarginLeft={-20}
              chartHasGrid
              isLLMComparison
            />
          </Grid>
          <Grid item xs={3.8}>
            <RankingWheelsCard
              icon={<VisibilityIcon color="#000000" />}
              title="Visibility Ranking"
              tooltip="Displays your brand's visibility ranking against competitors with a breakdown of visibility scores. All scores are out of 100"
              hasLLMselect
              height={345}
              hasRankingTypography
              wheelsGrid={12}
              isVertical
              comparsion_type="Competitors"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <RadarChartCard
              title="Competitive Analysis"
              tooltip="Compares your brand against competitors across relevant criterions for your industry."
              icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
              data={radarData}
              hasLLMselect
              height={543}
            />
          </Grid>
          <Grid item xs={5.85}>
            <StackedBarCard
              icon={
                <SentimentSatisfiedAltOutlinedIcon
                  style={{ width: 15, height: 15 }}
                />
              }
              title="Side-By-Side Sentiment Analysis"
              tooltip="Comparison of sentiment distribution across brands, showing percentages of positive, neutral, and negative perceptions. Helps evaluate LLMs perception for selected brands.

All score add up to 100"
              hasLLMselect
              height={543}
              chartWidth={470}
              chartHeight={480}
              isVertical
            />
          </Grid>{" "}
        </Grid>
        <LineChartCard
          icon={<VisibilityIcon color="#000000" />}
          title="Competitive Visibility Analysis"
          tooltip="Tracks visibility scores over time for your brand and key competitors. Use the dropdown to select your preferred LLM to view specific visibility scores. Higher scores indicate stronger brand visibility."
          hasCaledarSelect
          hasLLMselect
          height={328}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
        />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default Dashboard;
