import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { ML_API_URL } from "../../../../api";
import FormField from "../../../../CommonComponents/FormField";
import BlogWriterCard from "../Card/Card";
import TitlesSelectGroup from "./components/TitlesSelectGroup/TitlesSelectGroup";

const Titles = ({
  nextFunction,
  setGeneratedOutline,
  setLoadingAnimation,
  setLoadingAnimationText,
  generatedTitles,
}) => {
  const { values } = useFormikContext();

  const handleNext = useCallback(
    (step, setNextStep) => {
      setLoadingAnimation(true);
      setLoadingAnimationText("Your outline is currently generating");
      axios
        .post(`${ML_API_URL}/createai/generateoutline`, values)
        .then((response) => {
          setLoadingAnimation(false);
          const data = response.data;
          setGeneratedOutline(data.outline);
          values.summaries = data.summaries;
          setNextStep(step + 1);
        })
        .catch((err) => {
          setLoadingAnimation(false);
        });
    },
    [values, setGeneratedOutline, setLoadingAnimation, setLoadingAnimationText]
  );

  useEffect(() => {
    nextFunction(handleNext);
  }, [nextFunction, values]);
  return (
    <BlogWriterCard title="Choose an Engaging Title" width={900} marginTop={60}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 20,
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 14, color: "#797979" }}>
          Select a title that captivates and reflects the essence of your
          article as well as your chosen primary keyword for maximum SEO impact.
          You can also use your own title.
        </Typography>

        <Box style={{ display: "flex", flexDirection: "column", rowGap: 8 }}>
          <FormField
            type="input"
            name="title"
            placeholder="Top 10 Tips for E-commerce Success"
            customizedStyling={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& fieldset": {
                borderWidth: "1px",
                borderColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          />
        </Box>
        <TitlesSelectGroup titles={generatedTitles} />
      </Box>
    </BlogWriterCard>
  );
};
export default Titles;
