import React from "react";
import { IconButton } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CustomTooltip from "../CustomTooltip";
import { useLocation, useNavigate } from "react-router-dom";

const SettingsButton = ({ isCollapsed, handleSettingsClick }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const targetUrl = "/workspace-settings";

  const isActive = location.pathname.startsWith(targetUrl);

  const handleClick = () => {
    navigate(targetUrl);
    handleSettingsClick();
  };

  return (
    <CustomTooltip
      title="Settings"
      placement="right"
      disableHoverListener={!isCollapsed}
      arrow
    >
      <IconButton
        sx={{
          color: isActive ? "#0D0D0D" : "#666666",
          backgroundColor: isActive ? "#E3E8FE" : "transparent",
          borderRadius: isCollapsed ? "8px" : "50%",
          p: "5px",
          "&:hover": {
            backgroundColor: "#E3E8FE",
            boxShadow: "none",
          },
        }}
        onClick={handleClick}
      >
        <SettingsOutlinedIcon sx={{ fontSize: 24 }} />
      </IconButton>
    </CustomTooltip>
  );
};

export default SettingsButton;
