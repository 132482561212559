import { Grid } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityAcrossLanguages from "./Components/VisibilityAcrossLanguages/VisibilityAcrossLanguages";
import VisibilityAcrossRegions from "./Components/VisibilityAcrossRegions";
import GlobalIcon from "../../../../CommonComponents/customIcons/GlobalIcon";

const GlobalMonitoring = () => {
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Global Monitoring"
        description="Access a worldwide view of brand visibility, performance, and sentiment with heatmaps and regional data. Drill down into localized insights to support your global strategy effectively."
        hasGlobalFilters
        hasCountriesSeelect
        isGlobal
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <VisibilityAcrossLanguages />
          </Grid>
          <Grid item xs={5.85}>
            <VisibilityAcrossRegions />
          </Grid>{" "}
        </Grid>

        <LineChartCard
          icon={<GlobalIcon color="#000000" />}
          title="Performance by Region"
          tooltip="Tracks changes in visibility scores over time for both your brand and competitors within the specified region and LLM. The report can be refined using the dropdown filter to select a particular region and LLM."
          hasCaledarSelect
          hasLLMselect
          height={328}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
          isGlobal
        />
        {/* 
      <LineChartCard
        icon={<VisibilityIcon color="#000000" />}
        title="Performance by Language"
        tooltip="tooltip message here"
        hasCalendarSelect
        hasLLMselect
        height={328}
        chartHasXasix
        chartHasYaxis
        chartHeight={200}
        chartHasTooltip
        chartHasMarginTop={20}
        chartHasMarginLeft={-20}
        chartHasGrid
        data={data}
      /> */}
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default GlobalMonitoring;
