import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Box } from "@mui/material";
import WorkspaceSetUpCard from "../Card/Card";
const options = [
  {
    icon: <PersonAddAltIcon />,
    text: "Sign in to an existing workspace",
    nextStep: 2,
    disabled: true,
  },
  {
    icon: <SearchOutlinedIcon />,
    text: "Find workspaces",
    nextStep: 3,
    disabled: true,
  },
  {
    icon: <AddIcon />,
    text: "Create a new workspace",
    nextStep: 4,
    disabled: false,
  },
];
const Setup = ({ setCurrentStep }) => {
  return (
    <WorkspaceSetUpCard
      title="Add a workspace"
      backButton={false}
      nextButton={false}
    >
      {options.map((option) => (
        <Box
          style={{
            backgroundColor: "#F4F4F5",
            padding: "12px 14px",
            display: "flex",
            flexDirection: "row",
            columnGap: 8,
            borderRadius: 11,
            alignItems: "center",
          }}
        >
          {option.icon}
          <Typography
            style={{
              color: "#2D333A",
              fontSize: 14,
              fontWeight: 600,
              cursor: option.disabled ? "not-allowed" : "pointer",
            }}
            onClick={() => !option.disabled && setCurrentStep(option.nextStep)}
          >
            {option.text}
          </Typography>
        </Box>
      ))}
    </WorkspaceSetUpCard>
  );
};
export default Setup;
