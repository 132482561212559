import { Grid } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import RadarChartCard from "../../components/RadarChartCard/RadarChartCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import RankingStackedBarCard from "../../components/RankingStackedBarCard/RankingStackedBarCard";
import BarChartsCard from "../../components/BarChartsCard/BarChartsCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";

const ComparisonAnalysis = () => {
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Comparison Analysis"
        description="Compare brand performance against competitors side-by-side, analyzing visibility, ranking, sentiment, and performance. Identify areas for improvement and potential strategic shifts."
        hasGlobalFilters={false}
      >
        <BarChartsCard
          icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
          title="Visibility Score Analysis"
          tooltipMessage="Shows the visibility score for each brand across selected LLMs. Higher scores indicate stronger brand presence and discoverability. All scores are out of 100."
          height={221}
          hasLLMSelect
        />
        <LineChartCard
          icon={<VisibilityIcon color="#000000" />}
          title="Visibility Movement"
          tooltip="Tracks changes in visibility scores over time for your brand and competitors. You can filter this chart for a preferred LLM."
          hasCaledarSelect
          hasLLMselect
          height={328}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
        />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <RadarChartCard
              icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
              title="Competitive Analysis"
              tooltip="Compares your brand against competitors across multiple industry specific criterion.
            Note: the criterions might change based on the brand's industry vertical."
              hasLLMselect
              height={543}
            />
          </Grid>
          <Grid item xs={5.85}>
            <RankingWheelsCard
              icon={<VisibilityIcon color="#000000" />}
              title="Visibility Ranking"
              tooltip="Displays each brand’s visibility ranking by LLM model. The LLM model can be filtered for your preferred model. A higher percentage indicates a stronger visibility score. All score are out of 100."
              hasLLMselect
              height={543}
              hasRankingTypography
              wheelsGrid={6}
              isVertical
              comparsion_type="Competitors"
              wheelSize={100}
              gap={5}
            />
          </Grid>{" "}
        </Grid>

        <RankingStackedBarCard
          icon={<SentimentIcon color="#000000" />}
          title="Side-By-Side Sentiment Analysis"
          tooltip="Provides a comparison of sentiment scores across brands. Displays the percentage of positive, neutral, and negative sentiments for each brand. Score for each brand across sentiment adds up to 100. The report can be filtered for preferred LLM from the dropdown."
          hasLLMSelect
          height={232}
          gridTitle2="Company"
          gridTitle3=""
        />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default ComparisonAnalysis;
