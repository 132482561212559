import LineChart from "../../../../CommonComponents/Charts/LineChart";
import ChartCard from "../ChartCard/ChartCard";
import BrandsList from "../BrandsList";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { ML_AEO_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import {
  reorderKeys,
  transformCompetitorsLineChartData,
  transformLLMLineChartData,
} from "../../utils/DataUtils";
import { Typography } from "@mui/material";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLineData } from "../../consts/consts";

const LineChartCard = ({
  title,
  tooltip,
  hasLLMselect,
  hasCaledarSelect,
  height,
  chartHeight,
  chartHasTooltip,
  chartHasGrid,
  chartHasXasix,
  chartHasYaxis,
  chartHasMarginTop,
  chartHasMarginLeft,
  icon,
  isLLMComparison,
  hasBrandLabelOnly,
  isGlobal,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [initialBrands, setInititalBrands] = useState([]);
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const { noData } = useGlobalFilters();

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      if (!isGlobal) {
        const comparsion_type = isLLMComparison ? "LLMs" : "Competitors";
        const values = {
          brandId,
          comparsion_type,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
          LLMs: selectedLLM,
        };

        axios
          .post(`${ML_AEO_URL}/aeo/visibility-performance-line-chart`, values)
          .then((response) => {
            const data = response.data;

            const filteredData = isLLMComparison
              ? transformLLMLineChartData(data)
              : transformCompetitorsLineChartData(data, selectedLLM[0]);
            setData(filteredData.transformedData);
            setInititalBrands(filteredData?.keys);
            !isLLMComparison && setSelectedBrands(filteredData?.keys);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      } else {
        const values = {
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
          LLMs: selectedLLM,
          countries: ["United States"],
        };
        axios
          .post(`${ML_AEO_URL}/aeo/global-performance-line-chart`, values)
          .then((response) => {
            const data = response.data;

            const filteredData = isLLMComparison
              ? transformLLMLineChartData(data)
              : transformCompetitorsLineChartData(data, selectedLLM[0]);
            setData(filteredData.transformedData);
            setInititalBrands(filteredData?.keys);
            !isLLMComparison && setSelectedBrands(filteredData?.keys);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      }
    }
  }, [brandId, selectedLLM, isLLMComparison, date, isGlobal]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  const colorMap = useMemo(() => {
    const colors = [
      "#718CF9",
      "#FF9933",
      "#FFD633",
      "#33D6D6",
      "#33BBFF",
      "#99D6AD",
    ];
    const brands = reorderKeys(initialBrands, currentWorkspaceName);
    const map = {};
    brands.forEach((brand, index) => {
      map[brand] = colors[index % colors.length];
    });
    return map;
  }, [initialBrands]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessage={tooltip}
      hasCalendarSelect={hasCaledarSelect}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
    >
      <LineChart
        data={noData ? dummyLineData : data}
        xaxisDatakey="xAxis"
        hasXasix={chartHasXasix}
        hasYaxis={chartHasYaxis}
        height={chartHeight}
        hasTooltip={chartHasTooltip}
        marginTop={chartHasMarginTop}
        marginLeft={chartHasMarginLeft}
        hasGrid={chartHasGrid}
        mainArea={
          noData
            ? "NoGood"
            : isLLMComparison
            ? selectedLLM[0]
            : currentWorkspaceName
        }
        secondaryLinesDataKeys={selectedBrands.filter(
          (key) => key !== currentWorkspaceName
        )}
        colorMap={colorMap}
      />
      {!isLLMComparison && (
        <BrandsList
          brands={reorderKeys(initialBrands, currentWorkspaceName)}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          colorMap={colorMap}
        />
      )}
      {hasBrandLabelOnly && (
        <Typography
          style={{
            display: "inline-block",
            border: `1px solid #718CF9`,
            padding: "10px 12px",
            fontSize: 14,
            borderRadius: 11,
            cursor: "pointer",
            backgroundColor: "#718CF9",
          }}
        >
          {currentWorkspaceName}
        </Typography>
      )}
    </ChartCard>
  );
};
export default LineChartCard;
