import DNAStep from "../Step/Step";
import AudienceForm from "./Form/Form";

const Audience = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Audience"
      children={<AudienceForm brandDetails={brandDetails} />}
    />
  );
};
export default Audience;
