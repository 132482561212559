import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLLMRankingData, rankingsLables } from "../../consts/consts";
import ChartCard from "../ChartCard/ChartCard";
import RankingTypography from "../RankingTypography/RankingTypography";
import RankingWithProgressLine from "../RankingWithProgressLine/RankingWithProgressLine";
import TitlesGrid from "../TitlesGrid/TitlesGrid";

const RankingProgressCard = ({
  title,
  icon,
  tooltip,
  height,
  hasLLMSelect,
  hasTypographyRanking,
  gridTitle2,
  gridTitle3,
  isPerformance,
}) => {
  const { date, llm, noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const currentWorkspaceName = localStorage.getItem("worskapceName");

  useEffect(() => {
    if (!isPerformance) {
      const values = {
        brandId,
        comparsion_type: "Competitors",
        LLMs: selectedLLM,
      };
      axios
        .post(`${ML_AEO_URL}/aeo/brand-visibility-score-rank`, values)
        .then((response) => {
          const data = response.data;
          setData(data);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    } else {
      if (date.length > 0 && date[0] && date[1]) {
        const values = {
          brandId,
          comparsion_type: "Competitors",
          LLMs: selectedLLM,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
        };
        axios
          .post(`${ML_AEO_URL}/aeo/brand-performance-score-rank`, values)
          .then((response) => {
            const data = response.data;
            setData(data);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      }
    }
  }, [brandId, selectedLLM, date, isPerformance]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessage={tooltip}
      height={height}
      hasLLMselect={hasLLMSelect}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      {hasTypographyRanking && (
        <RankingTypography
          ranking={
            rankingsLables[
              data?.findIndex((item) => item.label === currentWorkspaceName)
            ]
          }
        />
      )}
      <TitlesGrid
        title2={gridTitle2}
        title3={gridTitle3}
        grid1={1}
        grid3={4.5}
      />
      <RankingWithProgressLine rankings={noData ? dummyLLMRankingData : data} />
    </ChartCard>
  );
};
export default RankingProgressCard;
