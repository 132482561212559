import { Button, Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BoxWithStatus from "../../../../CommonComponents/BoxWithStatus";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "12px !important",
    padding: "20px 8px 28px 18px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    boxShadow: "none !important",
    rowGap: 10,
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      border: "1px solid rgba(186, 193, 201, 0.50)",
    },
  },
  iconTypography: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    columnGap: 5,
  },
}));

const BrandDnaOverViewCard = ({ card }) => {
  const { title, isIncomplete } = card;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <BoxWithStatus isIncomplete={isIncomplete} statusFirst>
        <Button
          sx={{
            backgroundColor: "#F4F4F5",
            color: "#2D333A",
            padding: "2px 0px",
            fontSize: "14px",
            borderRadius: "25px",
            height: "auto",
            textTransform: "none",
          }}
        >
          Edit
        </Button>
      </BoxWithStatus>

      <Typography sx={{ fontSize: 26.48, color: "#141414", fontWeight: 450 }}>
        {title}
      </Typography>
    </Card>
  );
};

export default BrandDnaOverViewCard;
