import {
  Box,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../../api";
import AddMoreButton from "../../../../CommonComponents/FormField/components/AddMoreButton/AddMoreButton";
import SearchInput from "../../../../CommonComponents/SearchInput";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import AllPeopleList from "./AllPeopleList/AllPeopleList";
import RolesDropdown from "./RolesDropdown/RolesDropDown";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "12px !important",
    padding: "20px 8px 28px 18px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    boxShadow: "none !important",
    rowGap: 10,
    width: "100%",
    marginTop: 30,
  },

  subtitle: {
    fontSize: 14,
    color: "#000000",
    fontWeight: 500,
  },
}));
const People = () => {
  const { brandId } = useParams();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState();
  const [email, setEmail] = useState();
  const [peopleList, setPeopleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("author");
  const [loadingPeople, setLoadingPeople] = useState(false);

  const filteredData = useMemo(() => {
    return searchQuery
      ? peopleList.filter(
          (item) =>
            item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.last_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : peopleList;
  }, [peopleList, searchQuery]);

  const handleAddPerson = () => {
    setLoading(true);
    const values = { emails: [email], isAdmin: isAdmin };
    axios
      .post(`${API_URL}/brands/${brandId}/users`, values)
      .then((response) => {
        setPeopleList(response.data.users);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const refetch = () => {
    setLoadingPeople(true);
    axios
      .get(`${API_URL}/brands/${brandId}`)
      .then((response) => {
        setLoadingPeople(false);

        setPeopleList(response.data.users);
      })
      .catch((err) => {
        setLoadingPeople(false);
      });
  };
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [brandId]);

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setDropdownValue(value);

    if (value === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  return (
    <WorkspacePageTemplate
      title="People"
      navigateTo={`/workspace-settings/${brandId}`}
    >
      <Divider sx={{ width: "100%" }} />
      <Typography
        style={{
          fontSize: 14,
          color: "#797979",
          fontWeight: 600,
          marginTop: 10,
        }}
      >
        Invite People
      </Typography>
      <Card className={classes.card}>
        <Typography
          variant="h3"
          sx={{ fontSize: 22, color: "#000000", fontWeight: 450 }}
        >
          Invite people to your workspace by email
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontSize: 14, color: "#000000", fontWeight: 500 }}
        >
          To:
        </Typography>
        <TextField
          sx={{
            width: "50%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "1px solid rgba(0, 0, 0, 0.10)",
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
            },
          }}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@goodie.com"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControl>
                  <RolesDropdown
                    dropdownValue={dropdownValue}
                    handleDropdownChange={handleDropdownChange}
                  />
                </FormControl>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <AddMoreButton
            title={
              loading ? (
                <CircularProgress
                  style={{
                    width: 20,
                    height: 20,
                    color: "#2D333A",
                  }}
                />
              ) : (
                "Add Person"
              )
            }
            onClick={() => handleAddPerson()}
          />
        </div>
      </Card>
      <Divider sx={{ width: "100%", marginTop: 10 }} />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
          marginBottom: 60,
        }}
      >
        <Typography
          style={{
            fontSize: 14,
            color: "#797979",
            fontWeight: 600,
          }}
        >
          All People
        </Typography>
        <SearchInput
          placeholder="Search people..."
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          marginTop={10}
        />
      </Box>
      {loadingPeople ? (
        <div
          style={{
            position: "absolute",
            top: "95%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        </div>
      ) : (
        <AllPeopleList peopleList={filteredData} refetch={refetch} />
      )}
    </WorkspacePageTemplate>
  );
};
export default People;
