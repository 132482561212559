import React from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import CustomeTooltip from "./Components/Tooltip/Tooltip";

const secondaryColors = ["#FF9933", "#FFD633", "#33D6D6", "#33BBFF", "#99D6AD"];
const LineChart = ({
  data,
  xaxisDatakey = "xAxis",
  hasXasix,
  hasYaxis,
  hasLegend,
  hasGrid,
  hasTooltip,
  secondaryLinesDataKeys,
  mainArea = "ChatGpt",
  mainAreaColor = "#718CF9",
  mainAreaGardient = "rgba(255, 255, 255, 0.0001)",
  index,
  height = 117,
  marginTop = 0,
  marginLeft = 0,
  colorMap = {},
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: height,
        marginTop: marginTop,
        marginLeft: marginLeft,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart width={500} data={data}>
          {/* Grid */}
          {hasGrid && <CartesianGrid vertical={false} stroke="#F2F4F7" />}

          {/* XAxis */}
          {hasXasix && (
            <XAxis
              dataKey={xaxisDatakey}
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#8C8C8C", fontSize: 12, fontWeight: 450 }}
            />
          )}

          {/* YAxis */}
          {hasYaxis && (
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{
                fill: "#CCCCCC",
                fontSize: 12,
                fontWeight: 450,
              }}
            />
          )}

          {hasTooltip && (
            <Tooltip
              content={<CustomeTooltip />}
              cursor={{
                stroke: "#CCCCCC",
                strokeWidth: 1,
                strokeDasharray: "2 2",
              }}
            />
          )}
          {/* Legend */}
          {hasLegend && <Legend />}

          <defs>
            <linearGradient
              id={`mainGradient${index}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="50.74%" stopColor={mainAreaColor} stopOpacity={1} />
              <stop
                offset="100%"
                stopColor={mainAreaGardient}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={mainArea}
            stroke={mainAreaColor}
            fill={`url(#mainGradient${index})`}
            strokeWidth={2}
            fillOpacity={0.09}
          />

          {secondaryLinesDataKeys?.map((datakey, index) => (
            <Line
              type="monotone"
              dataKey={datakey}
              stroke={colorMap[datakey]}
              dot={false}
              strokeWidth={2}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
