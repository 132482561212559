import React from "react";
import { Tooltip } from "@mui/material";

const CustomTooltip = ({
  title,
  children,
  placement = "right",
  disableHoverListener = false,
  bgColor = "#1A1A1A",
  textColor = "#fff",
  arrow = false,
  tooltipPadding = "10px",
  tooltipMargin = "0px 0px 0px 10px !important",
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow={arrow}
      disableHoverListener={disableHoverListener}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: bgColor,
            color: textColor,
            boxShadow: "none",
            p: tooltipPadding,
            m: tooltipMargin,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16px",
            borderRadius: "10px",
          },
        },
        arrow: {
          sx: {
            color: bgColor,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
