import { Grid } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import StackedBarCard from "../../components/StackedBarCard/StackedBarCard";
import RankingStackedBarCard from "../../components/RankingStackedBarCard/RankingStackedBarCard";
import RadarChartCard from "../../components/RadarChartCard/RadarChartCard";
import AverageLineCard from "../Dashboard/Components/AverageLineCard/AverageLineCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";

const SentimentAnalysis = () => {
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Sentiment Analysis"
        description="View user sentiment trends (positive, negative, neutral) across models and over time. Assess how public perception aligns with overall brand performance and market success."
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={3.8}>
            <AverageLineCard
              icon={<SentimentIcon color="#000000" />}
              title="Average Current Sentiment"
              tooltip="Displays the sentiment trend for your brand over time, with a score out of 100. A score of 100 reflects the most positive brand sentiment."
              height={232}
              index={1}
              widget_type="Sentiment"
            />
          </Grid>{" "}
          <Grid item xs={7.9}>
            <RankingStackedBarCard
              icon={<SentimentIcon color="#000000" />}
              title="Side-By-Side Sentiment Analysis"
              tooltip="Compares sentiment scores across multiple brands side-by-side. Shows the percentage of positive, neutral, and negative sentiment for each brand which adds up to 100."
              hasLLMSelect
              height={232}
              gridTitle2="Company"
              gridTitle3=""
            />
          </Grid>
        </Grid>

        <StackedBarCard
          icon={<SentimentIcon color="#000000" />}
          title="Sentiment Analysis"
          tooltip="Tracks sentiment analysis over time, breaking down positive, neutral, and negative sentiments by month. The total of each month adds up to 100."
          hasCalendarSelect
          hasLLMselect
          height={328}
          isVertical
          chartHeight={270}
          chartWidth={1000}
          barSize={240}
          isSentimentTimeSeries
          XAxisDataKey="xAxis"
        />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <RadarChartCard
              icon={<VisibilityIcon color="#000000" />}
              title="Competitive Analysis"
              tooltip="Tracks sentiment analysis over time, breaking down positive, neutral, and negative sentiments by month. The total of each month adds up to 100."
              height={500}
              hasLLMselect
              comparsion_type="LLMs"
            />
          </Grid>
        </Grid>
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default SentimentAnalysis;
