import { Box, Typography } from "@mui/material";

const GeneratingCard = ({ padding = 50, marginTop = 5 }) => {
  return (
    <Box
      style={{
        backgroundColor: "#F5F5F5",
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        padding: padding,
        marginTop: marginTop,
      }}
    >
      <Typography style={{ fontWeight: 500, fontSize: 14 }}>
        Data will populate in 2 days
      </Typography>
    </Box>
  );
};
export default GeneratingCard;
