import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ML_API_URL } from "../../../../api";
import { useBlog } from "../../../../contexts/blogContext";
import BlogWriterCard from "../Card/Card";
import CustomEditor from "../TextEditor";

const Outline = ({
  nextFunction,
  generatedOutline,
  setOpenBlogDrawer,
  setLoadingAnimation,
  setLoadingAnimationText,
}) => {
  const navigate = useNavigate();
  const { values } = useFormikContext();
  const { brandId } = useParams();
  const [editedBlog, setEditedBlog] = useState("");
  const { setEditedBlog: setEditedBlogContext } = useBlog();

  const handleNext = () => {
    values.outline = editedBlog;
    setLoadingAnimation(true);
    setLoadingAnimationText("Your blog is currently generating");

    axios
      .post(`${ML_API_URL}/createai/generateblog`, values)
      .then((response) => {
        setLoadingAnimation(false);
        setEditedBlogContext(response.data.blogPost);

        navigate(`/dashboard/${brandId}/blog/${response.data.title}`);
        setOpenBlogDrawer(false);
      })
      .catch((err) => {
        setLoadingAnimation(false);
      });
  };
  useEffect(() => {
    nextFunction(handleNext);
  }, [nextFunction, values, editedBlog]);
  return (
    <BlogWriterCard title="Blog Outline" width={900} marginTop={60}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 40,
          marginTop: 5,
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 14, color: "#4D4D4D", maxWidth: 659 }}>
          Use the text editor to customize your blog post outline.
        </Typography>
        <CustomEditor
          generatedOutline={generatedOutline}
          setEditedBlog={setEditedBlog}
        />
      </Box>
    </BlogWriterCard>
  );
};
export default Outline;
