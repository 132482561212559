import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

const CustomSearchInput = ({ searchQuery, setSearchQuery }) => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search files..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: "11px",
        width: "388px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "11px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
        "& .MuiInputAdornment-root": {
          color: "#000",
        },
        "& input": {
          padding: "13px 16px",
        },
      }}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
};

export default CustomSearchInput;
