import { Avatar, Box, Menu, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ProfilePopup from "./components/ProfilePopup/ProfilePopup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, Link, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 1000,
    backgroundColor: "#F5F5F5",
    paddingTop: 18,
    paddingBottom: 20,
    borderBottom: "none",
    marginBottom: 128,
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
    color: "#8C8C8C",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  title: {
    textDecoration: "none",
    color: "#0D0D0D",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
}));
const Header = ({ isCollapsed }) => {
  const classes = useStyles();
  const location = useLocation();
  const { brandId } = useParams();
  const workspaceName = localStorage.getItem("worskapceName");

  const normalizedPath = location.pathname.replace(/\/+$/, "");

  const PageURL = {
    [`/dashboard/${brandId}`]: {
      title: `${workspaceName} Dashboard`,
    },
    [`/workspace-settings/${brandId}/brand-dna-overview`]: {
      title: "Brand DNA",
      parent: {
        title: `${workspaceName} Workspace Settings`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}`]: {
      title: `${workspaceName} Workspace Settings`,
    },
    [`/workspace-settings/${brandId}/knowledge-base`]: {
      title: "Knowledge Base",
      parent: {
        title: `${workspaceName} Workspace Settings`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}/people`]: {
      title: "People",
      parent: {
        title: `${workspaceName} Workspace Settings`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}/general`]: {
      title: "General",
      parent: {
        title: `${workspaceName} Workspace Settings`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}/connect-accounts`]: {
      title: "Connect Accounts",
      parent: {
        title: `${workspaceName} Workspace Settings`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/account/author-stamp`]: {
      title: "Personal Settings",
    },
    [`/ai-visibility/${brandId}/dashboard`]: {
      title: "Dashboard",
      parent: {
        title: `AI Visibility`,
      },
    },
    [`/ai-visibility/${brandId}/visibility-analysis`]: {
      title: "Visibility Analysis",
      parent: {
        title: `AI Visibility`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/performance-analysis`]: {
      title: "Performance Analysis",
      parent: {
        title: `AI Visibility`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/sentiment-analysis`]: {
      title: "Sentiment Analysis",
      parent: {
        title: `AI Visibility`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/comparison-analysis`]: {
      title: "Comparison Analysis",
      parent: {
        title: `AI Visibility`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/global-monitoring`]: {
      title: "Global Monitoring",
      parent: {
        title: `AI Visibility`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
  };
  const currentDashboardTab = PageURL[normalizedPath] || "Dashboard";
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const name =
    loggedInUser?.first_name + " " + loggedInUser?.last_name || "User";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{ pl: { xs: 3, md: 7 }, pr: { xs: 1, md: isCollapsed ? 20 : 40 } }}
      className={classes.fixedHeader}
    >
      <Typography>
        {currentDashboardTab.parent && (
          <>
            <Link to={currentDashboardTab.parent.link} className={classes.link}>
              {currentDashboardTab.parent.title}
            </Link>
            <span className={classes.link}> / </span>
          </>
        )}
        <span className={classes.title}> {currentDashboardTab.title}</span>
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Avatar
          sx={{
            width: "36px",
            height: "36px",
            backgroundColor: "#E7E7E7",
            color: "#797979",
            textAlign: "center",
            fontSize: 20.25,
            fontWeight: 400,
          }}
        >
          {name.charAt(0)}
        </Avatar>
        <Typography
          sx={{
            fontSize: 14,
            color: "#2D333A",
            fontWeight: 500,
          }}
        >
          {name}
        </Typography>

        <ExpandMoreIcon />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ top: "25px", right: "0px", left: "15px" }}
      >
        <ProfilePopup />
      </Menu>
    </Box>
  );
};
export default Header;
