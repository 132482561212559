import DNAStep from "../Step/Step";
import BrandValuesForm from "./Form/Form";

const BrandValues = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Brand Values"
      children={<BrandValuesForm brandDetails={brandDetails} />}
    />
  );
};
export default BrandValues;
