import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import BlogWriterCard from "../Card/Card";
import ReferenceCard from "./components/Card/Card";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ML_API_URL } from "../../../../api";
import axios from "axios";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";

const References = ({
  references,
  nextFunction,
  setLoading,
  setFooterErrorMessage,
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors: formikErrors,
  } = useFormikContext();
  const [selectedReferences, setSelectedReferences] = useState([]);
  const [dynamicReferences, setDynamicReferences] = useState([""]);
  const [errors, setError] = useState("");

  const handleSelect = (reference) => {
    const isSelected = selectedReferences.includes(reference.url);
    if (isSelected) {
      setSelectedReferences(
        selectedReferences.filter((r) => r !== reference.url)
      );
    } else {
      setSelectedReferences([...selectedReferences, reference.url]);
    }
  };
  useEffect(() => {
    const nonEmptyDynamicReferences = dynamicReferences.filter(
      (reference) => !!reference
    );
    nonEmptyDynamicReferences.length >= 1
      ? setFieldValue("references", [
          ...selectedReferences,
          ...nonEmptyDynamicReferences,
        ])
      : setFieldValue("references", selectedReferences);
  }, [selectedReferences, setFieldValue, formikErrors, dynamicReferences]);

  const handleAddMore = () => {
    setDynamicReferences([...dynamicReferences, ""]);
  };
  const handleNext = useCallback(
    (step, setNextStep) => {
      const customReferences = dynamicReferences.filter((item) => item !== "");
      if (customReferences.length > 0) {
        setLoading(true);
        const values = { urls: customReferences };
        axios
          .post(`${ML_API_URL}/createai/validateurls`, values)
          .then((response) => {
            const data = response.data;
            setLoading(false);
            if (data.all_valid) setNextStep(step + 1);
            else {
              setError("Failed to validate urls");
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      } else setNextStep(step + 1);
    },
    [dynamicReferences, setLoading]
  );

  useEffect(() => {
    nextFunction(handleNext);
  }, [nextFunction, values, handleNext]);

  useEffect(() => {
    formikErrors.references
      ? setFooterErrorMessage("Please select up to 4")
      : setFooterErrorMessage("");
  }, [formikErrors.references, setFooterErrorMessage]);

  return (
    <BlogWriterCard
      title="Select Reference Articles"
      width={900}
      marginTop={60}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 50,
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Typography
          style={{
            fontSize: 14,
            color: "#797979",
            maxWidth: 684,
            fontWeight: 450,
            lineHeight: 1.4,
          }}
        >
          Select reference articles to guide the content of your blog post.
          Goodie analyzes these to determine ideal word length, heading
          distribution, and keyword strategy based on competitor and keyword
          research.
        </Typography>

        <Box style={{ display: "flex", flexDirection: "column", rowGap: 30 }}>
          <FieldArray name="references">
            {() => (
              <>
                {dynamicReferences.map((item, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 2,
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      placeholder="Add your own links here ..."
                      value={item}
                      onChange={(event) => {
                        setFieldTouched("references", true);
                        const newValue = event.target.value;
                        const updatedReferences = [...dynamicReferences];
                        updatedReferences[index] = newValue;
                        setDynamicReferences(updatedReferences);

                        setFieldValue("references", [
                          ...selectedReferences,
                          ...updatedReferences.filter(
                            (ref) => ref.trim() !== ""
                          ), // Remove empty references
                        ]);
                      }}
                      fullWidth
                      InputProps={{
                        endAdornment: index > 0 && (
                          <DeleteOutlineOutlinedIcon
                            onClick={() => {
                              const updatedItems = dynamicReferences.filter(
                                (_, i) => i !== index
                              );
                              setDynamicReferences(updatedItems);
                              setFieldValue("references", [
                                ...selectedReferences,
                                ...updatedItems.filter(
                                  (ref) => ref.trim() !== ""
                                ), // Remove empty references
                              ]);
                            }}
                            style={{
                              fontSize: 25,
                              color: "#797979",
                              cursor: "pointer",
                            }}
                          />
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0.1)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid",
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid",
                            borderColor: "black",
                          },
                        },
                      }}
                    />
                  </Box>
                ))}

                <Button
                  onClick={handleAddMore}
                  sx={{
                    backgroundColor: "#F4F4F5",
                    textTransform: "none",
                    color: "#797979",
                    alignSelf: "flex-start",
                  }}
                >
                  <AddCircleOutlineSharpIcon
                    sx={{ marginRight: "5px", fontSize: "14px" }}
                  />{" "}
                  Add Example
                </Button>
              </>
            )}
          </FieldArray>
          {errors && <ErrorTypography errorMessage={errors} />}
          <Grid container spacing={3}>
            {references.map((reference, index) => (
              <Grid item xs={12} md={6} key={index}>
                <ReferenceCard
                  reference={reference}
                  onSelect={() => {
                    handleSelect(reference);
                    setFieldValue("references", selectedReferences);
                    setFieldTouched("references", true);
                  }}
                  isSelected={selectedReferences.includes(reference.url)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </BlogWriterCard>
  );
};

export default References;
