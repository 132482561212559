import { Divider } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLLMRankingData, rankingsLables } from "../../consts/consts";
import ChartCard from "../ChartCard/ChartCard";
import RankingTypography from "../RankingTypography/RankingTypography";
import RankingWithWheel from "../RankingWithWheel/RankingWithWheel";

const RankingWheelsCard = ({
  title,
  tooltip,
  hasLLMselect,
  height,
  hasRankingTypography,
  wheelsGrid,
  isVertical,
  icon,
  comparsion_type = "LLMs",
  isPerformance,
  wheelSize,
  gap,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
    noData,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [rankings, setRankings] = useState([]);
  const { brandId } = useParams();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const currentWorkspaceName = localStorage.getItem("worskapceName");

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    if (!isPerformance) {
      const values = { brandId, comparsion_type, LLMs: selectedLLM };
      axios
        .post(`${ML_AEO_URL}/aeo/brand-visibility-score-rank`, values)
        .then((response) => {
          const data = response.data;
          setRankings(data);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    } else {
      const values = {
        brandId,
        comparsion_type,
        LLMs: selectedLLM,
      };
      axios
        .post(`${ML_AEO_URL}/aeo/brand-performance-score-rank`, values)
        .then((response) => {
          const data = response.data;
          setRankings(data);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    }
  }, [brandId, selectedLLM, comparsion_type, isPerformance]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessage={tooltip}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
    >
      {hasRankingTypography ? (
        <RankingTypography
          ranking={
            rankingsLables[
              rankings?.findIndex((item) => item.label === currentWorkspaceName)
            ]
          }
        />
      ) : (
        <Divider
          sx={{
            height: "1px",
            borderColor: "#F2F4F7",
            borderBottomWidth: "1px",
            margin: "20px 0px",
          }}
        />
      )}
      <RankingWithWheel
        isVertical={isVertical}
        grid={wheelsGrid}
        rankings={noData ? dummyLLMRankingData : rankings}
        wheelSize={wheelSize}
        gap={gap}
      />
    </ChartCard>
  );
};
export default RankingWheelsCard;
