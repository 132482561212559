import { Step, StepLabel, Stepper, StepIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StepperCompletedIcon from "../customIcons/StepperCompletedIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-root": {
      color: "transparent",
      border: "1.5px solid grey",
      borderRadius: "50%",
      fontSize: 24,
    },
    "& .Mui-active .MuiStepIcon-root": {
      fill: theme.palette.primary.main,
      border: "none",
    },
    "& .Mui-completed .MuiStepIcon-root": {
      fill: theme.palette.primary.light,
      border: "none",
    },
    "& .MuiStepIcon-root .MuiStepIcon-text": {
      fill: "#797979",
    },
    "& .MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
      fill: "white",
    },
    "& .MuiStepIcon-root.Mui-completed .MuiSvgIcon-root": {
      color: "#000",
    },
    "& .MuiStepLabel-label": {
      fontSize: 12.08,
      fontWeight: "600 !important",
    },
    "& .MuiStepLabel-label span": {
      fontWeight: "600 !important",
    },
    "& .Mui-completed .MuiStepLabel-label": {
      color: "#141414",
    },
  },
}));

const CustomStepIcon = (props) => {
  const { completed } = props;

  if (completed) {
    return <StepperCompletedIcon />;
  }

  return <StepIcon {...props} />;
};

const CustomStepper = ({ steps, activeStep, setActiveStep }) => {
  const classes = useStyles();

  return (
    <Stepper
      className={classes.root}
      activeStep={activeStep}
      orientation="vertical"
      connector={null}
    >
      {steps.map((label, index) => (
        <Step
          onClick={() => index < activeStep && setActiveStep(index)}
          key={label}
          sx={{
            backgroundColor: activeStep === index ? "#E3E4FC" : "transparent",
            padding: 2,
            paddingLeft: 5,
            borderTop: index === 0 && "1.5px solid",
            borderBottom: "1.5px solid",
            borderColor: activeStep === index ? "#E3E4FC" : "#E1E1E1",
            cursor: "pointer",
          }}
        >
          <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
export default CustomStepper;
