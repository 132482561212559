import { TextField, Typography } from "@mui/material";
import WorkspaceSetUpCard from "../Card/Card";
import { API_URL } from "../../../../../../api";
import { useState } from "react";
import axios from "axios";

const AddWorkspace = ({
  setCurrentStep,
  backButton = true,
  hasClose = false,
  handleClose = () => {},
  setBrandName: SetSavedBrandName,
}) => {
  const userId = localStorage.getItem("userId");
  const [brandName, setBrandName] = useState("");

  const handleChange = (event) => {
    setBrandName(event.target.value);
  };

  const handleNext = () => {
    const values = { name: brandName, users: [userId] };
    axios
      .post(`${API_URL}/brands/`, values)
      .then((response) => {
        localStorage.setItem("brandId", response.data.brand.id);
        setCurrentStep(5);
        SetSavedBrandName(response.data.brand.name);
        axios
          .get(`${API_URL}/accounts/${userId}`)
          .then((response) => {
            const userData = response.data;
            localStorage.setItem("loggedInUser", JSON.stringify(userData));
          })
          .catch((error) => {});
      })
      .catch((err) => {
        console.log("test error", err);
      });
  };

  return (
    <WorkspaceSetUpCard
      title="What’s the name of your workspace or company?"
      handleBack={() => setCurrentStep(1)}
      handleNext={() => handleNext()}
      backButton={backButton}
      hasClose={hasClose}
      handleClose={handleClose}
    >
      <Typography style={{ fontSize: 14, color: "#CCCFD1" }}>
        This will be the name of your Goodie workspace – choose something that
        your team will recognize.
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
        <Typography style={{ fontSize: 15 }}>Workspace name</Typography>
        <TextField
          value={brandName}
          onChange={handleChange}
          placeholder="Company"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "1px solid rgba(0, 0, 0, 0.10)",
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
            },
          }}
        />
      </div>
    </WorkspaceSetUpCard>
  );
};
export default AddWorkspace;
