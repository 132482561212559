import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  Collapse,
  Divider,
  IconButton,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import HomeIcon from "../customIcons/Home";
import Doc from "../customIcons/doc";
import SidebarButton from "./components/SideBarButton/SidebarButton";
import GenerateBlogModal from "../../pages/Dashboard/components/GenerateBlogModal";
import CollapseIcon from "../customIcons/CollapseIcon";
import { API_URL } from "../../api";
import axios from "axios";
import WorkspacesDropdown from "./components/WorkspacesDropdown";
import SettingsButton from "./components/SettingsButton/SettingsButton";
import WorkspaceImage from "./components/WorkspaceImage/WorkspaceImage";
import WorkspaceName from "./components/WorkspaceName";
import CustomTooltip from "./components/CustomTooltip";
import ElectricIcon from "../customIcons/ElectricIcon";
import ExpandMore from "@mui/icons-material/ExpandLess";
import RightArrow from "@mui/icons-material/KeyboardArrowRightOutlined";
import AIVisibilityIcon from "../customIcons/AIVisibilityIcon";
import AudioModalityIcon from "../customIcons/AudioModalityIcon";
import AIVisibilityButtons from "./components/AIVisibilityButtons/AIVisibilityButtons";

const Sidebar = ({ onCollapseChange }) => {
  const [openAIVisibility, setOpenAIVisibility] = useState(false);
  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const { brandId: id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBrandDnaCompleted, setisBrandDnaCompleted] = useState(false);
  const [workspaceName, setWorkspaceName] = useState("Workspaces");
  const open = Boolean(anchorEl);
  const brandId = id || localStorage.getItem("brandId");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  const workspaceData = JSON.parse(localStorage.getItem("workspace"));
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;

  useEffect(() => {
    if (userBrands?.length > 0) {
      setWorkspaceName(
        userBrands?.find((brand) => parseInt(brand.id) === parseInt(brandId))
          ?.name
      );
    }
  }, [workspaceData, userBrands, brandId]);

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        const workspaceData = response.data;
        localStorage.setItem("workspace", JSON.stringify(workspaceData));
        setisBrandDnaCompleted(
          workspaceData.business_step_completed &&
            workspaceData.persona_step_completed &&
            workspaceData.brand_voice_step_completed &&
            workspaceData.competitors_step_completed &&
            workspaceData.global_step_completed
        );
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [brandId]);

  const handleClick = (section) => {
    if (section === "AIVisibility") setOpenAIVisibility(!openAIVisibility);
  };

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
    onCollapseChange(!isCollapsed); // Notify parent component (Layout.js)
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: isCollapsed ? 55 : 304,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isCollapsed ? 55 : 304,
          boxSizing: "border-box",
          backgroundColor: "#fff",
          color: "#fff",
          p: isCollapsed ? "16px 10px" : "16px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: isCollapsed ? "0px" : "30px",
          flexDirection: isCollapsed ? "column" : "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: isCollapsed ? "20px" : "0px",
            maxWidth: "73%",
          }}
        >
          <CustomTooltip
            title={
              isCollapsed ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <WorkspaceImage isCollapsed={isCollapsed} inTooltip={true} />
                  <WorkspaceName
                    workspaceName={workspaceName}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    inTooltip={true}
                  />
                </Box>
              ) : (
                ""
              )
            }
            placement="right"
            disableHoverListener={!isCollapsed}
            bgColor="#1A1A1A"
            arrow
          >
            <WorkspaceImage isCollapsed={isCollapsed} />
          </CustomTooltip>

          {!isCollapsed && (
            <WorkspaceName
              workspaceName={workspaceName}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              inTooltip={false}
            />
          )}
        </Box>
        <box>
          <SettingsButton
            isCollapsed={isCollapsed}
            handleSettingsClick={() =>
              navigate(`/workspace-settings/${brandId}`)
            }
          />
          {!isCollapsed && (
            <IconButton
              sx={{
                color: "#8C8C8C",
                p: "0px",
              }}
              onClick={handleCollapseClick}
            >
              <CollapseIcon sx={{ fontSize: 24, color: "#8C8C8C" }} />
            </IconButton>
          )}
        </box>
      </Box>
      {!isCollapsed && <Divider sx={{ borderColor: "#E5E5E5" }} />}

      <Box
        sx={{
          pb: isCollapsed ? "0px" : "15px",
          pt: isCollapsed ? "0px" : "15px",
        }}
      >
        <List
          component="nav"
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 0,
            pt: isCollapsed ? "27px" : "0px",
          }}
        >
          <SidebarButton
            to={`/dashboard/${brandId}/`}
            primary="Dashboard"
            IconComponent={HomeIcon}
            isCollapsed={isCollapsed}
            sx={{ mb: isCollapsed ? "27px" : "0px" }}
            collapsedPadding="5px"
            expandedPadding="8px"
          />
          <SidebarButton
            primary="AI Content Writer"
            IconComponent={Doc}
            handleClick={() => setOpenBlogDrawer(true)}
            isCollapsed={isCollapsed}
            sx={{
              mb: isCollapsed ? "27px" : "0px",
            }}
            collapsedPadding="5px"
            expandedPadding="8px"
          />
          {!isCollapsed && (
            <ListItem
              button
              onClick={() => handleClick("AIVisibility")}
              sx={{
                p: "5px 8px 5px 8px",
                margin: isCollapsed ? "0px 0px 12px 0px" : "0px 0px 5px 0px",
                borderRadius: "8px",
                justifyContent: isCollapsed ? "center" : "flex-start",
                "&:hover": {
                  backgroundColor: "#E3E8FE",
                  boxShadow: "none",
                  borderRadius: "8px",
                },
              }}
            >
              <ListItemIcon
                sx={{ minWidth: 0, mr: isCollapsed ? 0 : 1, color: "#666666" }}
              >
                <AIVisibilityIcon />
              </ListItemIcon>
              {!isCollapsed && (
                <ListItemText
                  primary="AI Visibility"
                  sx={{ color: "#666666" }}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    },
                  }}
                />
              )}
              {openAIVisibility ? (
                <ExpandMore
                  sx={{ color: "#666666", width: "18px", height: "18px" }}
                />
              ) : (
                <RightArrow
                  sx={{ color: "#666666", width: "18px", height: "18px" }}
                />
              )}
            </ListItem>
          )}
          <Collapse
            in={openAIVisibility}
            timeout="auto"
            unmountOnExit
            sx={{
              ml: isCollapsed ? "0px" : "24px",
              mb: isCollapsed ? "0px" : "5px",
            }}
          >
            <List
              component="div"
              disablePadding
              sx={{
                gap: isCollapsed ? "12px" : "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isCollapsed && <AIVisibilityButtons />}
            </List>
          </Collapse>

          <CustomTooltip
            title={
              isCollapsed ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      gap: isCollapsed ? "12px" : "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#666666",
                        fontSize: "14px",
                        fontWeight: 500,
                        margin: 0,
                        padding: "0px 0px 0px 12px",
                        lineHeight: "18px",
                      }}
                    >
                      AI Visibility
                    </Typography>
                    <AIVisibilityButtons
                      sx={{ width: "156px" }}
                      expandedPadding="8px 12px"
                      borderRadius="0px"
                    />
                  </List>
                </Box>
              ) : (
                ""
              )
            }
            placement="right"
            disableHoverListener={!isCollapsed}
            bgColor="#fff"
            tooltipMargin="0px 0px 0px 20px !important"
            tooltipPadding="10px 0px"
          >
            {isCollapsed && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SidebarButton
                  IconComponent={AIVisibilityIcon}
                  isCollapsed={isCollapsed}
                  collapsedPadding="5px"
                  expandedPadding="8px"
                />
              </Box>
            )}
          </CustomTooltip>

          <SidebarButton
            // to={`/workspace-settings/${brandId}/brand-dna-overview`}
            primary="AI Optimization"
            IconComponent={ElectricIcon}
            isCollapsed={isCollapsed}
            collapsedPadding="5px"
            expandedPadding="8px"
          />
          <SidebarButton
            // to={`/workspace-settings/${brandId}/brand-dna-overview`}
            primary="Audio Modality"
            IconComponent={AudioModalityIcon}
            isCollapsed={isCollapsed}
            collapsedPadding="5px"
            expandedPadding="8px"
          />
        </List>
      </Box>

      <GenerateBlogModal
        openBlogDrawer={openBlogDrawer}
        setOpenBlogDrawer={setOpenBlogDrawer}
        brandId={brandId}
      />
      <WorkspacesDropdown
        anchorEl={anchorEl}
        open={open}
        setAnchorEl={setAnchorEl}
        userBrands={userBrands}
      />
      <IconButton
        sx={{
          display: isCollapsed ? "block" : "none",
          position: "absolute",
          bottom: "10px",
          p: "0px",
        }}
        onClick={handleCollapseClick}
      >
        <CollapseIcon sx={{ fontSize: 24 }} />
      </IconButton>
    </Drawer>
  );
};

export default Sidebar;
