import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import RadarChart from "../../../../CommonComponents/Charts/RadarChart";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyRadarData } from "../../consts/consts";
import { reorderKeys } from "../../utils/DataUtils";
import BrandsList from "../BrandsList";
import ChartCard from "../ChartCard/ChartCard";

export function transformToRadarData(inputData) {
  const labels = [];
  const marketKeys = new Set();

  const radarData = inputData?.map((item) => {
    const { label, market_performance } = item;
    labels.push(label);

    Object.keys(market_performance).forEach((key) => marketKeys.add(key));

    const radarItem = {
      benchmark: label,
      ...market_performance,
    };

    return radarItem;
  });

  const marketPerformanceKeys = Array.from(marketKeys);

  return {
    radarData,
    labels,
    marketPerformanceKeys,
  };
}

const RadarChartCard = ({
  title,
  tooltip,
  hasLLMselect,
  height,
  icon,
  comparsion_type = "Competitors",
}) => {
  const { llm, noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [initialBrands, setInititalBrands] = useState([]);
  const [data, setData] = useState([]);
  const { brandId } = useParams();
  const currentWorkspaceName = localStorage.getItem("worskapceName");

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    const values = {
      brandId,
      LLMs: selectedLLM,
      comparsion_type,
    };
    axios
      .post(`${ML_AEO_URL}/aeo/market-performance`, values)
      .then((response) => {
        const data = response.data;
        const transformedData = transformToRadarData(data);
        console.log("testtt radardata", transformedData);
        setData(transformedData.radarData);
        setInititalBrands(
          reorderKeys(
            transformedData.marketPerformanceKeys,
            currentWorkspaceName
          )
        );
        setSelectedBrands(
          reorderKeys(
            transformedData.marketPerformanceKeys,
            currentWorkspaceName
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, [brandId, selectedLLM, comparsion_type]);

  const colorMap = useMemo(() => {
    const colors = [
      "#718CF9",
      "#FF9933",
      "#FFD633",
      "#33D6D6",
      "#33BBFF",
      "#99D6AD",
    ];
    const map = {};
    initialBrands.forEach((brand, index) => {
      map[brand] = colors[index % colors.length];
    });
    return map;
  }, [initialBrands]);

  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessage={tooltip}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      {noData ? (
        <>
          <RadarChart
            data={dummyRadarData}
            radarTitles={["NoGood", "Competitor1", "Competitor2"]}
            isLLM={comparsion_type === "LLMs"}
          />
          <BrandsList
            brands={["NoGood", "Competitor1", "Competitor2"]}
            isLLM={comparsion_type === "LLMs"}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
          />{" "}
        </>
      ) : (
        data.length > 0 && (
          <>
            <RadarChart
              data={data}
              radarTitles={selectedBrands}
              isLLM={comparsion_type === "LLMs"}
              colorMap={colorMap}
            />
            <BrandsList
              brands={reorderKeys(initialBrands, currentWorkspaceName)}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              isLLM={comparsion_type === "LLMs"}
              colorMap={colorMap}
            />
          </>
        )
      )}
    </ChartCard>
  );
};
export default RadarChartCard;
