import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import FormikTextField from "../../../../CommonComponents/FormField/components/TextField/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const CredentialsSection = ({ showPasswordTextfield, handleEdit }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
      <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
        <Typography style={{ fontSize: 15 }}>Email</Typography>
        <FormikTextField
          name="email"
          placeholder="user@nogood.io"
          endAdornment={
            showPasswordTextfield && (
              <Typography onClick={() => handleEdit()}>Edit</Typography>
            )
          }
          disabled={showPasswordTextfield}
          margin={false}
        />
      </div>
      {showPasswordTextfield && (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
          <Typography style={{ fontSize: 15, color: "#2D333A" }}>
            Password
          </Typography>
          <FormikTextField
            name="password"
            placeholder="Enter Password"
            ispassword={!showPassword}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            margin={false}
          />
          <Typography style={{ color: "#797979", fontSize: 14 }}>
            Forgot Password?
          </Typography>
        </div>
      )}
    </div>
  );
};
export default CredentialsSection;
