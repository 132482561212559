import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import AccountsCard from "../Card/Card";
import CredentialsSection from "../CredentialsSection/CredentialsSection";
import GoogleLogin from "../GoogleLogin";
import OrSeperator from "../OrSeperator/OrSeperator";
import EastIcon from "@mui/icons-material/East";
import { useState } from "react";
import { Typography } from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [cardTitle, setCardTitle] = useState("Log in to Goodie");
  const [buttonText, setButtonText] = useState("Continue with email");
  const [showPasswordTextfield, setShowPasswordTextfield] = useState(false);
  const navigate = useNavigate();
  
  const handleEdit = () => {
    setShowPasswordTextfield(false);
    setCardTitle("Log in to Goodie");
    setButtonText("Continue with email");
  };
  const handleOnClick = () => {
    setCardTitle("Enter your password");
    setShowPasswordTextfield(true);
    setButtonText("Sign in");
  };
  return (
    <Wrapper>
      <AccountsCard title={cardTitle}>
        <CredentialsSection
          showPasswordTextfield={showPasswordTextfield}
          handleEdit={handleEdit}
        />
        <ChipButton
          iconAfter={!showPasswordTextfield && <EastIcon />}
          label={buttonText}
          backgroundColor="#C7F565"
          textColor="#2D333A"
          hoverBackgroundColor="#EBFFC1"
          hoverTextColor="#2D333A"
          hoverBorderColor="#EBFFC1"
          fullWidth
          onClick={() => handleOnClick()}
        />
        <Typography style={{ fontSize: 14, color: "#2D333A" }}>
          Don’t have an account?{" "}
          <span
            style={{ color: "#797979", cursor: "pointer" }}
            onClick={() => navigate("/signup")}
          >
            Sign up
          </span>
        </Typography>
        {!showPasswordTextfield && (
          <>
            <OrSeperator />
            <GoogleLogin />
          </>
        )}
      </AccountsCard>{" "}
    </Wrapper>
  );
};
export default Login;
