import ChartCard from "../../../../components/ChartCard/ChartCard";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import GeneratingCard from "../../../../components/GeneratingCard/GeneratingCard";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";

const VisibilityAcrossLanguages = () => {
  const { llm } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      icon={<GlobalIcon color="#000000" />}
      title="Visibility Across Languages"
      tooltipMessage="Shows your brand’s visibility score across different languages and selected LLMs. Higher scores indicate stronger brand presence in each language."
      height={254}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      <GeneratingCard padding={70} marginTop={20} />
    </ChartCard>
  );
};
export default VisibilityAcrossLanguages;
