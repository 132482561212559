import { Box, Typography } from "@mui/material";
import LineChart from "../../../../../../CommonComponents/Charts/LineChart";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import GeneratingCard from "../../../../components/GeneratingCard/GeneratingCard";
import { dummyLineData } from "../../../../consts/consts";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";

const OverallLineChartCard = ({
  data,
  mainArea = "Nike",
  isIncrease,
  index,
}) => {
  const { Score, Movement } = data;
  const { noData } = useGlobalFilters();

  let mainAreaColor = isIncrease || noData ? "#399B4E" : "#E50000";
  let backgroundColor =
    isIncrease || noData ? "rgba(241, 250, 243, 1)" : "rgba(255, 239, 240, 1)";

  return (
    <Box style={{ display: "flex", flexDirection: "column", rowGap: 0 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          alignItems: "baseline",
        }}
      >
        <Typography style={{ fontSize: 30, fontWeight: 450 }}>
          {noData ? "1.8" : Score}
        </Typography>
        <Box
          style={{
            color: mainAreaColor,
            backgroundColor: backgroundColor,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            columnGap: 2,
            fontSize: 14,
            padding: 2,
          }}
        >
          {isIncrease ? (
            <TrendingUpOutlinedIcon />
          ) : (
            <TrendingDownOutlinedIcon />
          )}
          {noData ? "1.8" : Movement}%
        </Box>
      </div>
      {noData ? (
        <>
          <LineChart
            data={noData ? dummyLineData : data}
            mainArea={noData ? "NoGood" : mainArea}
            mainAreaColor={mainAreaColor}
            index={index}
            height={117}
          />
          <Typography
            style={{ fontSize: 12, fontWeight: 400, color: "#797979" }}
          >
            Displaying data from all time
          </Typography>
        </>
      ) : (
        <GeneratingCard />
      )}
    </Box>
  );
};
export default OverallLineChartCard;
