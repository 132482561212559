import React from "react";
const CollapseIcon = ({ color = "#8C8C8C", width, height }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Sidebar">
      <path
        id="Icon"
        d="M19.3333 24L19.3333 10M20.2667 24L13.7333 24C12.4265 24 11.7731 24 11.274 23.7457C10.835 23.522 10.478 23.165 10.2543 22.726C10 22.2268 10 21.5735 10 20.2667L10 13.7333C10 12.4265 10 11.7731 10.2543 11.274C10.478 10.835 10.835 10.478 11.274 10.2543C11.7731 10 12.4265 10 13.7333 10L20.2667 10C21.5735 10 22.2269 10 22.726 10.2543C23.165 10.478 23.522 10.835 23.7457 11.274C24 11.7731 24 12.4265 24 13.7333L24 20.2667C24 21.5735 24 22.2269 23.7457 22.726C23.522 23.165 23.165 23.522 22.726 23.7457C22.2269 24 21.5735 24 20.2667 24Z"
        stroke="#8C8C8C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default CollapseIcon;
