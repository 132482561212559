import ChartCard from "../../../../components/ChartCard/ChartCard";
import LLMGridRanking from "../LLMGridRanking/LLMGridRanking";
import { useEffect, useState } from "react";
import { ML_AEO_URL } from "../../../../../../api";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Divider } from "@mui/material";
import VisibilityIcon from "../../../../../../CommonComponents/customIcons/VisibilityIcon";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { dummyLLMRankingData } from "../../../../consts/consts";

const BrandVisibilityScoreCard = () => {
  const [visibilityScores, setVisibilityScores] = useState([]);
  const { brandId } = useParams();
  const { noData } = useGlobalFilters();

  useEffect(() => {
    const values = { brandId, comparsion_type: "LLMs" };
    axios
      .post(`${ML_AEO_URL}/aeo/brand-visibility-score-rank`, values)
      .then((response) => {
        const data = response.data;
        setVisibilityScores(data);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, [brandId]);
  return (
    <ChartCard
      icon={<VisibilityIcon color="#000000" />}
      title="Brand Visibility Score"
      tooltipMessage="Ranks visibility scores across each LLM individually. Higher scores indicating positive sentiment and lower scores indicating negative sentiment.

      All scores are out of 100"
      height={232}
    >
      <Divider
        sx={{
          height: "1px",
          borderColor: "#F2F4F7",
          borderBottomWidth: "1px",
          margin: "12px 0px",
        }}
      />
      <LLMGridRanking
        rankings={noData ? dummyLLMRankingData : visibilityScores}
      />
    </ChartCard>
  );
};
export default BrandVisibilityScoreCard;
