const Wrapper = ({ children, backgroundColor = "#F4F4F5" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </div>
  );
};
export default Wrapper;
