import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import chatgptIcon from "../../../../assets/images/chatgpt.png";
import perplexityIcon from "../../../../assets/images/perplexity.png";
import geminiIcon from "../../../../assets/images/gemini.png";

const LLMs = {
  ChatGpt: chatgptIcon,
  Perplexity: perplexityIcon,
  Gemini: geminiIcon,
};

const colors = [
  "#718CF9",
  "#FF9933",
  "#FFD633",
  "#33D6D6",
  "#33BBFF",
  "#99D6AD",
];
const llmColors = ["#C7F656", "#99DEE8", "#D0BEDB"];

const BrandsList = ({
  brands,
  selectedBrands,
  setSelectedBrands,
  isLLM,
  colorMap = {},
}) => {
  useEffect(() => {
    if (selectedBrands.length === 0 && brands.length > 0) {
      setSelectedBrands([brands[0]]);
    }
  }, [selectedBrands, brands, setSelectedBrands]);
  const handleBrandClick = (brand) => {
    setSelectedBrands((prevSelected) => {
      const newSelection = prevSelected.includes(brand)
        ? prevSelected.filter((b) => b !== brand)
        : [...prevSelected, brand];
      return newSelection.length === 0 ? [brands[0]] : newSelection;
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 8,
        justifyContent: isLLM && "center",
      }}
    >
      {brands.map((brand, index) => (
        <>
          <Typography
            key={brand}
            onClick={() => handleBrandClick(brand)}
            style={{
              border: `1px solid ${
                isLLM
                  ? llmColors[index]
                  : Object.keys(colorMap).length > 0
                  ? colorMap[brand]
                  : colors[index]
              }`,
              padding: "10px 12px",
              fontSize: 14,
              borderRadius: 11,
              cursor:
                brand === brands[0] && selectedBrands.length === 1
                  ? "default"
                  : "pointer",
              backgroundColor: selectedBrands?.includes(brand)
                ? isLLM
                  ? llmColors[index]
                  : Object.keys(colorMap).length > 0
                  ? colorMap[brand]
                  : colors[index]
                : "transparent",
              display: "flex",
              flexDirection: "row",
              columnGap: 2,
              alignItems: "center",
            }}
          >
            {isLLM && (
              <img
                src={LLMs[brand]}
                style={{ width: 14, height: 14 }}
                alt={brand}
              />
            )}
            {brand}
          </Typography>
        </>
      ))}
    </Box>
  );
};

export default BrandsList;
