import React, { useCallback } from "react";
import { Box, Button } from "@mui/material";

const ChipGroup = ({ options, name, field, form, multiSelect, isDynamic }) => {
  const { value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const customOptions = value
    ? isDynamic
      ? multiSelect
        ? Array.from(new Set([...options, ...value]))
        : Array.from(new Set([...options, value]))
      : options
    : options;

  const handleChipClick = useCallback(
    (option) => {
      setFieldTouched(name, true);

      if (multiSelect) {
        const newValue = value?.includes(option)
          ? value.filter((item) => item !== option)
          : [...value, option];
        setFieldValue(name, newValue);
      } else {
        setFieldValue(name, option);
      }
    },
    [name, value, multiSelect, setFieldValue, setFieldTouched]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {customOptions.map((option) => (
        <Button
          key={option}
          onClick={() => handleChipClick(option)}
          sx={{
            textTransform: "none",
            padding: "10px 11px 10px 11px",
            backgroundColor: multiSelect
              ? value?.includes(option)
                ? "#EBFFC1"
                : "transparent"
              : value === option
              ? "#EBFFC1"
              : "transparent",
            color: "#4D4D4D",
            borderRadius: "11px",
            border: "1px solid #E1E1E1",
            "&:hover": {
              backgroundColor: "#EBFFC1",
            },
          }}
        >
          {option}
        </Button>
      ))}
    </Box>
  );
};

export default ChipGroup;
