import { Box, Typography, Snackbar } from "@mui/material";
import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import PageTemplate from "../../../../CommonComponents/PageTemplate/PageTemplate";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ReactQuill from "react-quill";
import { marked } from "marked";
import TurndownService from "turndown";
import { useBlog } from "../../../../contexts/blogContext";

const FinalBlog = () => {
  const { title } = useParams();
  const [open, setOpen] = useState(false);
  const { editedBlog, setEditedBlog } = useBlog();

  const handleCopy = () => {
    const turndownService = new TurndownService();
    const markdown = turndownService.turndown(editedBlog);
    navigator.clipboard
      .writeText(markdown)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <PageTemplate>
        <Typography
          variant="h2"
          style={{
            fontWeight: 500,
            fontSize: 40,
            margin: 0,
            padding: 0,
            lineHeight: 1,
            maxWidth: 684,
            marginBottom: 20,
          }}
        >
          {title}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ color: "#797979", fontSize: 14, fontWeight: 450 }}
          >
            Use the text editor to customize your blog post.{" "}
          </Typography>
          <ChipButton
            iconBefore={<ContentPasteOutlinedIcon />}
            label="Copy to Clipboard"
            backgroundColor="#F4F4F5"
            textColor="#141414"
            padding="10px 10px"
            fontSize="16px"
            borderColor={"#E1E1E1"}
            hoverBackgroundColor={"#E1E1E1"}
            fontWeight={450}
            columnGap={0.5}
            onClick={() => handleCopy()}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 85px 70px 85px",
            boxShadow: "none",
            flexDirection: "column",
            paddingTop: 20,
          }}
        >
          {/* <Box
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 20,
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <Typography
              style={{ fontSize: 14, color: "#4D4D4D", maxWidth: 659 }}
              dangerouslySetInnerHTML={{ __html: marked(finalBlog) }}
            />
          </Box> */}
          <ReactQuill
            value={marked(editedBlog)}
            onChange={(content) => setEditedBlog(content)}
            // modules={modules}
            theme="snow"
          />
        </Box>
      </PageTemplate>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Blog copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};
export default FinalBlog;
