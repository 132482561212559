import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import PageTemplate from "../../../../CommonComponents/PageTemplate/PageTemplate";
import WestIcon from "@mui/icons-material/West";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WorkspacePageTemplate = ({ title, children, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <PageTemplate>
      {navigateTo && (
        <ChipButton
          iconBefore={<WestIcon />}
          onClick={() => navigate(navigateTo)}
          label="Back"
          backgroundColor="transparent"
          textColor="#141414"
          hoverBackgroundColor="#E1E1E1"
          hoverTextColor="#141414"
          hoverBorderColor="#E1E1E1"
          padding="5px 15px"
        />
      )}
      {title && (
        <Typography
          style={{ fontSize: 40, fontWeight: 600 }}
          sx={{ mt: { xs: 2, md: navigateTo ? 4 : 1 }, mb: { xs: 2, md: 5 } }}
        >
          {title}
        </Typography>
      )}
      {children}
    </PageTemplate>
  );
};
export default WorkspacePageTemplate;
