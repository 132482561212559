import React from "react";
import { Box } from "@mui/material";

const Tag = ({ children }) => {
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: "#EBFFC1",
        padding: "4.627px 11.104px 4.627px 7.402px",
        borderRadius: "28.684px",
        fontSize: "12.954px",
        color: "#003310",
        fontWeight: "450",
        lineHeight: "15.315px",
        letterSpacing: "-0.13px",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};

export default Tag;
