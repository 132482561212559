import { Card, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";
import { useNavigate } from "react-router-dom";
import LLMDropDown from "../LLMDropdown/LLMDropdown";
import DateFilter from "../DateFilter/DateFilter";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";

const ChartCard = ({
  icon,
  title,
  tooltipMessage,
  navigateTo,
  hasLLMselect,
  hasCalendarSelect,
  children,
  height = 345,
  selectedLLM = ["ChatGpt"],
  setSelectedLLM,
  date,
  setDate,
  initialDate,
}) => {
  const navigate = useNavigate();
  const { noData } = useGlobalFilters();
  return (
    <Card
      style={{
        borderRadius: 10,
        padding: noData ? 0 : 15,
        boxShadow: "none",
        height: height,
        overflow: noData ? "hidden" : "scroll",
        position: "relative",
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: noData && 1,
        }}
      >
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 4,
            alignItems: "center",
          }}
        >
          {icon}
          <Typography
            style={{ color: "#0D0D0D", fontWeight: 500, fontSize: 14 }}
          >
            {title}
          </Typography>
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>{tooltipMessage}</span>
            }
          >
            <InfoOutlinedIcon
              sx={{ color: "#8C8C8C", width: 15, height: 15 }}
            />
          </Tooltip>
        </Grid>

        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            columnGap: 2,
          }}
        >
          {hasCalendarSelect && initialDate && (
            <DateFilter
              value={date}
              setValue={setDate}
              startDate={initialDate[0]}
              endDate={initialDate[1]}
            />
          )}

          {hasLLMselect && (
            <LLMDropDown
              selectedLLM={selectedLLM}
              setSelectedLLM={setSelectedLLM}
            />
          )}
          {navigateTo && (
            <IconButton
              style={{ backgroundColor: "#F5F5F5", padding: 7 }}
              onClick={() => navigate(navigateTo)}
            >
              <ArrowOutwardSharpIcon
                style={{ color: "#000000", width: 15, height: 15 }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <div
        style={{
          position: "relative",
          height: noData && "100%",
          overflowY: noData && "auto",
        }}
      >
        {noData && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.01)",
              zIndex: 1,
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        )}

        {children}
      </div>
    </Card>
  );
};
export default ChartCard;
