import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "../../../../../../CommonComponents/customIcons/CheckedIcon";

const TitlesSelectGroup = ({ titles }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setFieldValue("title", value);
  };
  return titles?.map((title) => (
    <Box
      sx={{
        padding: "10px 15px",
        border: `1px solid ${
          values?.title === title ? "#000000" : "#BAC1C980"
        } !important`,
        borderRadius: "8px",
      }}
    >
      <FormControlLabel
        key={title}
        control={
          <Checkbox
            value={title}
            checked={values?.title === title}
            onChange={(event) => handleCheckboxChange(event)}
            icon={<CheckBoxOutlineBlankIcon sx={{ color: "#BAC1C980" }} />}
            checkedIcon={<CheckedIcon />}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          />
        }
        label={
          <Typography style={{ fontSize: 14, color: "#000000" }}>
            {title}
          </Typography>
        }
      />
    </Box>
  ));
};
export default TitlesSelectGroup;
