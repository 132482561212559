import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const getPerformanceColor = (percentage) => {
  if (percentage >= 80) {
    return "#009933";
  } else if (percentage >= 50) {
    return "#FFD633";
  } else {
    return "#E50000";
  }
};
const PercentageWheel = ({ percentage, width = 64, height = 64 }) => {
  return (
    <div style={{ width: width, height: height }}>
      <CircularProgressbar
        value={percentage.toFixed(2)}
        text={`${percentage.toFixed(2)}%`}
        strokeWidth={9}
        styles={buildStyles({
          strokeLinecap: "round",
          textSize: "20px",
          trailColor: "#E5E5E5",
          textColor: "#0D0D0D",
          pathColor: getPerformanceColor(percentage),
        })}
      />
    </div>
  );
};
export default PercentageWheel;
