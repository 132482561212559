import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import StackedBarChart from "../../../../CommonComponents/Charts/StackedBarChart/StackedBarChart";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyStackedData } from "../../consts/consts";
import {
  spreadSentimentData,
  transformSentimentOverTime,
} from "../../utils/DataUtils";
import ChartCard from "../ChartCard/ChartCard";

const StackedBarCard = ({
  title,
  tooltip,
  hasLLMselect,
  hasCalendarSelect,
  height,
  isVertical,
  icon,
  chartHeight,
  chartWidth,
  barSize,
  isSentimentTimeSeries,
  XAxisDataKey,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
    noData,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [data, setData] = useState([]);
  const { brandId } = useParams();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);

  useEffect(() => {
    if (!isSentimentTimeSeries) {
      const values = { brandId, LLMs: selectedLLM };
      axios
        .post(`${ML_AEO_URL}/aeo/sentiment-competitor`, values)
        .then((response) => {
          const transformedData = spreadSentimentData(response.data);
          setData(transformedData);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    } else {
      if (date.length > 0 && date[0] && date[1]) {
        const values = {
          brandId,
          LLMs: selectedLLM,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
        };
        axios
          .post(`${ML_AEO_URL}/aeo/sentiment-time-series`, values)
          .then((response) => {
            const transformedData = transformSentimentOverTime(
              response.data,
              selectedLLM
            );
            setData(transformedData);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      }
    }
  }, [brandId, selectedLLM, date, isSentimentTimeSeries]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessage={tooltip}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      hasCalendarSelect={hasCalendarSelect}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
    >
      <StackedBarChart
        isVertical={isVertical}
        data={noData ? dummyStackedData : data}
        height={chartHeight}
        width={chartWidth}
        barSize={barSize}
        XAxisDataKey={XAxisDataKey}
      />
    </ChartCard>
  );
};
export default StackedBarCard;
