import { Box, Menu, MenuItem, Modal } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useState } from "react";
import AddWorkspace from "../../../../pages/Accounts/Components/Workspace/Components/AddWorkspace/AddWorkspace";
import Wrapper from "../../../../pages/Accounts/Components/Wrapper/Wrapper";
import InviteUsers from "../../../../pages/Accounts/Components/Workspace/Components/InviteUsers/InviteUsers";
import WelcomeDNA from "../../../../pages/Accounts/Components/Workspace/Components/WelcomeDNA/WeclomeDNA";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "92%",
  bgcolor: "background.paper",
  borderRadius: "35px",
  boxShadow: 24,
  height: "80%",
  outline: "none",
};

const WorkspacesDropdown = ({
  anchorEl,
  open,
  setAnchorEl,
  userBrands = [],
}) => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const [openAddWorkspace, setOpenAddWorkspace] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [brandName, setBrandName] = useState("");

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        sx: {
          width: "285px",
          boxShadow: "none",
          backgroundColor: "#FFFFFF",
          padding: "7px",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {userBrands?.map((brand) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#141414",
              borderRadius: 1,
              width: 27,
              height: 27,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AddRoundedIcon style={{ color: "#141414", fontSize: 18 }} />
          </Box>
          <MenuItem
            onClick={() => {
              localStorage.setItem("brandId", brand.id);
              navigate(`/dashboard/${brand.id}/`);
              setAnchorEl(null);
              localStorage.setItem("worskapceName", brand.name);
            }}
            disableRipple
            sx={{
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:focus": {
                backgroundColor: "transparent",
              },
              "&:active": {
                backgroundColor: "transparent",
              },
              fontSize: "14px",
              color: "#141414",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              lineHeight: 1,
            }}
          >
            {brand.name}
            {parseInt(brand.id) === parseInt(brandId) && (
              <span
                variant="h5"
                style={{
                  fontSize: "12px",
                  color: "#797979",
                }}
              >
                selected
              </span>
            )}
          </MenuItem>
        </div>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 5,
        }}
        onClick={() => {
          setOpenAddWorkspace(true);
        }}
      >
        <Box
          sx={{
            backgroundColor: "#E1E1E1",
            borderRadius: 1,
            width: 27,
            height: 27,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddRoundedIcon style={{ color: "#797979", fontSize: 18 }} />
        </Box>
        <MenuItem
          onClick={() => {}}
          disableRipple
          sx={{
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&:active": {
              backgroundColor: "transparent",
            },
            fontSize: "14px",
            color: "#797979",
            fontWeight: 500,
          }}
        >
          Create new workspace
        </MenuItem>
      </div>
      <Modal
        open={openAddWorkspace}
        onClose={() => {
          console.log("Closing modal");
          setOpenAddWorkspace(false);
        }}
      >
        <Wrapper backgroundColor="transparent">
          {currentStep === 1 && (
            <AddWorkspace
              backButton={false}
              setCurrentStep={setCurrentStep}
              hasClose={true}
              handleClose={() => setOpenAddWorkspace(false)}
              setBrandName={setBrandName}
            />
          )}
          {currentStep === 5 && (
            <InviteUsers
              backButton={false}
              setCurrentStep={setCurrentStep}
              hasClose={true}
              handleClose={() => setOpenAddWorkspace(false)}
              brandName={brandName}
            />
          )}
          {currentStep === 6 && (
            <WelcomeDNA
              setCurrentStep={setCurrentStep}
              backButton={false}
              onClose={() => setOpenAddWorkspace(false)}
            />
          )}
        </Wrapper>
      </Modal>
    </Menu>
  );
};
export default WorkspacesDropdown;
