import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import BarChart from "../../../../CommonComponents/Charts/BarChart";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyCompetitorsData } from "../../consts/consts";
import { reorderTransformedData } from "../../utils/DataUtils";
import ChartCard from "../ChartCard/ChartCard";

const colors = [
  "#718CF9",
  "#FF9933",
  "#FFD633",
  "#33D6D6",
  "#33BBFF",
  "#99D6AD",
];

const transformData = (inputArray) => {
  return inputArray.map((item, index) => ({
    brand: item.label,
    value: Math.round(item.score * 100) / 100,
    fill: colors[index] || "#000000",
    backgroundValue: 100 - item.score,
  }));
};
const BarChartsCard = ({ icon, title, tooltip, height, hasLLMSelect }) => {
  const { noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(["ChatGpt"]);
  const [visibilityScores, setVisibilityScores] = useState(
    noData ? transformData(dummyCompetitorsData) : []
  );
  const { brandId } = useParams();
  const currentWorkspaceName = localStorage.getItem("worskapceName");

  useEffect(() => {
    const values = {
      brandId,
      comparsion_type: "Competitors",
      LLMs: selectedLLM,
    };
    axios
      .post(`${ML_AEO_URL}/aeo/brand-visibility-score-rank`, values)
      .then((response) => {
        const data = response.data;
        const orderedData = reorderTransformedData(data, currentWorkspaceName);
        setVisibilityScores(transformData(orderedData));
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, [brandId, selectedLLM, currentWorkspaceName]);

  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessage={tooltip}
      height={height}
      hasLLMselect={hasLLMSelect}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      <BarChart data={visibilityScores} />
    </ChartCard>
  );
};
export default BarChartsCard;
