import React from "react";
import { Box } from "@mui/material";
import { forwardRef } from "react";

const WorkspaceImage = forwardRef(
  ({ isCollapsed, inTooltip, ...props }, ref) => (
    <Box
      ref={ref}
      sx={{
        backgroundColor: "#0D0D0D",
        borderRadius: "4px",
        width: "24px",
        minWidth: "24px",
        height: "24px",
        mr: inTooltip ? "12px" : isCollapsed ? "0px" : "8px",
        mb: inTooltip ? "0px" : isCollapsed ? "12px" : "0px",
      }}
      {...props}
    />
  )
);

export default WorkspaceImage;
