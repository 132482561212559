import React from "react";
import { ListItemText, IconButton } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";

const WorkspaceNameDropdown = ({
  workspaceName,
  anchorEl,
  setAnchorEl,
  inTooltip,
}) => {
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ListItemText
        primary={workspaceName}
        sx={{ color: inTooltip ? "#fff" : "#0D0D0D" }}
        primaryTypographyProps={{
          sx: {
            fontSize: inTooltip ? "14px" : "22px",
            fontWeight: inTooltip ? 500 : 450,
            cursor: "pointer",
            lineHeight: inTooltip ? "18px" : "normal",
          },
        }}
        onClick={handleClick}
      />
      <IconButton
        onClick={handleClick}
        sx={{ color: inTooltip ? "#fff" : "#8C8C8C", p: 0 }}
      >
        {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
      </IconButton>
    </>
  );
};

export default WorkspaceNameDropdown;
