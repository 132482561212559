import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Card,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import axios from "axios";

import { useParams } from "react-router-dom";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import { API_URL, ML_API_URL } from "../../../../api";

const validateWordCount = (text) => {
  const wordCount = text.trim().split(/\s+/).length;
  return wordCount >= 800 && wordCount <= 3000;
};

const KnowledgeBase = () => {
  const userId = JSON.parse(localStorage.getItem("loggedInUser"))?.id;
  const { brandId } = useParams();

  const [knowledgeBase, setKnowledgeBase] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const [err, setError] = useState(false);
  const [saved, setSaved] = useState();
  const wordCount = useMemo(() => {
    return knowledgeBase.trim().split(/\s+/).length;
  }, [knowledgeBase]);

  useEffect(() => {
    setInitialLoading(true);
    axios
      .get(`${API_URL}/brands/${brandId}/knowledgebase`)
      .then((response) => {
        setKnowledgeBase(response.data.content);
        setInitialLoading(false);
      })
      .catch((error) => {
        setInitialLoading(false);
      });
  }, [brandId]);

  const handleSave = () => {
    const values = {
      userId: userId,
      text_input: knowledgeBase,
      brandId: brandId,
    };
    setLoading(true);
    setSaved(false);
    setError(false);
    const isValid = validateWordCount(knowledgeBase);

    if (isValid) {
      axios
        .post(`${ML_API_URL}/createai/knowledgebase`, values)
        .then((response) => {
          setLoading(false);
          setSaved(true);
        })
        .catch((err) => {
          setSaved(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError(
        wordCount < 800
          ? "Knowledge base should be more than 800 words"
          : wordCount > 3000
          ? "Knowledge base  should be less than 3000 words"
          : ""
      );
    }
  };
  return (
    <WorkspacePageTemplate title="Knowledge Base">
      <Typography
        variant="subtitle1"
        sx={{
          color: "#4D4D4D",
          fontSize: "14px",
          fontWeight: 450,
          maxWidth: "596px",
          mt: -4,
        }}
      >
        Add an example of your best written blogs or articles so Goodie
        understands your brand’s aspirational tone of voice and writing style.
        Copy and past your best written work below.
      </Typography>

      <Card
        style={{
          borderRadius: "10px",
          padding: "20px 8px 28px 18px",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          boxShadow: "none",
          rowGap: 10,
          width: "75%",
          marginTop: 80,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#141414",
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "44px",
            letterSpacing: "-0.48px",
            mb: "1px",
          }}
        >
          Reference
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#4D4D4D",
            fontSize: "14px",
            fontWeight: "450",
            lineHeight: "20px",
          }}
        >
          Article should be between 800-3000 words max.
        </Typography>

        <TextField
          sx={{
            width: "98%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "1px solid rgba(0, 0, 0, 0.10)",
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
            },
          }}
          value={knowledgeBase}
          rows={7}
          multiline
          onChange={(e) => {
            setSaved(false);
            setKnowledgeBase(e.target.value);
          }}
          placeholder={
            initialLoading
              ? "Loading..."
              : "Lorem ipsum dolor sit amet consectetur. Mollis a etiam aliquam nibh fermentum sollicitudin lectus neque lectus. Ipsum cursus rutrum a purus cursus. Tellus nibh euismod nisi quam magnis orci eu risus nisi. Ac facilisis consectetur proin est quis faucibus. Velit lectus ante suscipit nibh ultrices. Mollis nam cum vitae urna euismod imperdiet id lectus"
          }
        />

        {err && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ErrorTypography errorMessage={err} hasBackground={false} />{" "}
            <Typography sx={{ fontSize: "14px", color: "#E9301C" }}>
              {wordCount}/3000
            </Typography>{" "}
          </Box>
        )}

        <div style={{ display: "flex", flexDirection: "row" }}>
          {" "}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#C7F565",
              color: "#141414",
              padding: "10px 12px",
              borderRadius: "11px",
              fontSize: "14px",
              fontWeight: 450,
              lineHeight: "20px",
              boxShadow: "none",
              minWidth: "auto",
              "&:hover": {
                backgroundColor: "#C7F565",
              },
            }}
            onClick={() => handleSave()}
            startIcon={saved ? <CheckCircleOutlineSharpIcon /> : null}
          >
            {loading ? (
              <CircularProgress
                style={{
                  width: 20,
                  height: 20,
                  color: "#2D333A",
                }}
              />
            ) : saved ? (
              "Saved"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Card>
    </WorkspacePageTemplate>
  );
};

export default KnowledgeBase;
