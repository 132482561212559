import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import chatgptIcon from "../../../../assets/images/chatgpt.png";
import perplexityIcon from "../../../../assets/images/perplexity.png";
import geminiIcon from "../../../../assets/images/gemini.png";

const LLMIconMapping = {
  ChatGpt: chatgptIcon,
  Gemini: geminiIcon,
  Perplexity: perplexityIcon,
};
const RankingWithProgressLine = ({ rankings }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        marginTop: 7.5,
      }}
    >
      {rankings?.map((ranking, index) => (
        <Grid
          container
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={1}>
            <Typography
              style={{ color: "#8C8C8C", fontWeeight: 400, fontSize: 14 }}
            >
              {index + 1}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 4,
            }}
          >
            {(ranking.icon || LLMIconMapping[ranking.label]) && (
              <img
                src={ranking.icon || LLMIconMapping[ranking.label]}
                style={{ width: 14, height: 14 }}
                alt={ranking.label}
              />
            )}

            <Typography
              style={{ color: "#0D0D0D", fontWeeight: 400, fontSize: 14 }}
            >
              {ranking.label}
            </Typography>
          </Grid>
          <Grid item xs={6.8}>
            <LinearProgress
              value={ranking.score.toFixed(2)}
              variant="determinate"
              sx={{
                borderRadius: 5,
                height: 10,
                width: "100%",
                alignSelf: "center",
                backgroundColor: "rgba(57, 155, 78, 0.1)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#009933",
                  borderRadius: 5,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={1.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography
              style={{ color: "#666666", fontWeeight: 400, fontSize: 14 }}
            >
              {" "}
              {ranking.score.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
export default RankingWithProgressLine;
