import FormField from "../../../../../../../CommonComponents/FormField";
import InputSection from "../../InputSection/InputSection";

import {
  ageRangeOptions,
  genderOptions,
  interestsOptions,
  jobFunctionsOptions,
  jobTitlesOptions,
} from "./consts";
const PersonaForm = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 30 }}>
      <InputSection
        title="Age range"
        required
        description="Select up to 3 options"
        rowGap={10}
        children={
          <FormField
            name="age_ranges"
            type="choiceChipsGroup"
            options={ageRangeOptions}
            multiSelect
            errorMessage
          />
        }
      />
      <InputSection
        title="Gender"
        required
        rowGap={10}
        children={
          <FormField
            name="genders"
            type="dynamicChoiceChipsGroup"
            options={genderOptions}
            multiSelect
            errorMessage
          />
        }
      />
      <InputSection
        title="Interests"
        required
        rowGap={10}
        children={
          <FormField
            name="interests"
            type="dynamicChoiceChipsGroup"
            options={interestsOptions}
            multiSelect
          />
        }
      />{" "}
      <InputSection
        title="Job Titles"
        required
        description="Select all relevant job titles for your target audience"
        rowGap={10}
        children={
          <FormField
            name="job_titles"
            type="dynamicChoiceChipsGroup"
            options={jobTitlesOptions}
            multiSelect
          />
        }
      />
      <InputSection
        title="Job Functions"
        required
        description="Select all relevant job functions for your target audience"
        rowGap={10}
        children={
          <FormField
            name="job_functions"
            type="dynamicChoiceChipsGroup"
            options={jobFunctionsOptions}
            multiSelect
          />
        }
      />
      <InputSection
        title="Customer Pain points"
        required
        description="Please identify at least two key pain points your customers face—these are the challenges, frustrations, or unmet needs that your product or service helps to solve."
        rowGap={10}
        children={
          <FormField
            type="dynamicMultipleTextFields"
            name="pain_points"
            variant="outlined"
            fullwidth
            multiline
            minCharacters={100}
            placeholder="Type here"
            requiredInputsCount={2}
          />
        }
      />{" "}
      <InputSection
        title="Customer Desires"
        required
        description="Please describe at least two key desires of your customers—these are the goals, aspirations, or positive outcomes they are looking to achieve with the help of your product or service."
        rowGap={10}
        children={
          <FormField
            type="dynamicMultipleTextFields"
            name="desires"
            variant="outlined"
            fullwidth
            multiline
            minCharacters={100}
            placeholder="Type here"
            requiredInputsCount={2}
          />
        }
      />
    </div>
  );
};
export default PersonaForm;
