import ChartCard from "../../../../components/ChartCard/ChartCard";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../../../api";
import BarChart from "../../../../../../CommonComponents/Charts/BarChart";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";
import { dummyRegionsData } from "../../../../consts/consts";

function flattenData(data) {
  return data.map((item) => ({
    label: item.label,
    value: Object.values(item.scores)[0].toFixed(2) || null,
    fill: "#718CF9",
    backgroundValue: 100 - Object.values(item.scores)[0].toFixed(2),
  }));
}

const VisibilityAcrossRegions = () => {
  const { llm, noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [data, setData] = useState(noData ? flattenData(dummyRegionsData) : []);
  const { brandId } = useParams();

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  useEffect(() => {
    const values = {
      brandId,
      countries: ["United States"],
      LLMs: selectedLLM,
    };
    axios
      .post(`${ML_AEO_URL}/aeo/global-visibility-regions`, values)
      .then((response) => {
        const data = response.data;
        setData(flattenData(data));
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, [brandId, selectedLLM]);
  return (
    <ChartCard
      icon={<GlobalIcon color="#000000" />}
      title="Visibility Across Regions"
      tooltipMessage="Displays your brand’s visibility score across various regions and selected LLM. Higher scores indicate stronger brand presence in each country or region. All score out of 100."
      height={254}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      <BarChart data={data} xAxisDataKey="label" width={200} height={190} />
    </ChartCard>
  );
};
export default VisibilityAcrossRegions;
