import FormField from "../../../../../../../CommonComponents/FormField";
import GridInputSection from "./components/GridInputSection";

const CompetitionForm = () => {
  return (
    <div>
      <GridInputSection
        grid1Title={"Competitor company name"}
        grid2Title={"Competitor wesbite"}
        children={
          <FormField
            type="dynamicGridTextFields"
            name={`competitors`}
            variant="outlined"
            fullwidth
            requiredInputsCount={1}
            placeholder="Company"
            secondaryPlaceholder=""
            secondaryStartAdornment="https://"
            subName="name"
            secondarySubName="website"
          />
        }
      />
    </div>
  );
};
export default CompetitionForm;
