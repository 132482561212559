import { Box, Typography } from "@mui/material";
import animationLoader from "../../assets/gifs/Loading_Animation.gif";

const AnimationLoader = ({ text }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 15,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={animationLoader}
        alt="loading animation"
        style={{ width: "130px", height: "auto" }}
      />
      <Typography style={{ fontWeight: 500, fontSize: 20 }}>{text}</Typography>
    </Box>
  );
};
export default AnimationLoader;
