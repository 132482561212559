export const coreThemesOptions = [
  "Innovation",
  "Sustainability",
  "Health",
  "Finance",
  "Technology",
  "Lifestyle",
  "Education",
  "Entertainment",
];

export const contentCategoriesOptions = [
  "Blog Posts",
  "Videos",
  "Infographics",
  "Social Media Posts",
  "White Papers",
  "Case Studies",
];
