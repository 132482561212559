import { CircularProgress, Grid, Typography } from "@mui/material";
import PageTemplate from "../../CommonComponents/PageTemplate/PageTemplate";
import ShortcutCard from "./components/ShorcutCard/ShortcutsCard";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../api";
import axios from "axios";
import IncompleteDna from "./components/IncompleteDna/IncompleteDna";
import RecentSection from "./components/RecentSection/RecentSection";
import GenerateBlogModal from "./components/GenerateBlogModal";
import Doc from "../../CommonComponents/customIcons/doc";
import StepperModal from "../../CommonComponents/StepperModal";
import { getValidationSchema, steps } from "../BrandDna/conts";
import BrandVoice from "../BrandDna/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Competition from "../BrandDna/components/BrandDNAForm/components/Competition/Competition";
import Business from "../BrandDna/components/BrandDNAForm/components/Business";
import Persona from "../BrandDna/components/BrandDNAForm/components/Persona";
import Global from "../BrandDna/components/BrandDNAForm/components/Global";

const Dashboard = () => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDnaCompleted, setIsDnaCompleted] = useState(false);
  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const [openDNAModal, setOpenDNAModal] = useState(false);
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;

  const companyName =
    localStorage.getItem("worskapceName") ||
    userBrands?.filter((brand) => parseInt(brand.id) === parseInt(brandId))[0]
      ?.name;

  const shortcuts = useMemo(
    () => [
      {
        icon: <Doc color="#003310" width={24} height={24}></Doc>,
        title: "Write a blog",
        description:
          "Craft engaging, informative, SEO-friendly blog posts, tailored to your audience",
        backgroundColor: "#EBFFC1",
        onClick: () => setOpenBlogDrawer(true),
        isReady: true,
      },
      {
        icon: (
          <SignalCellularAltOutlinedIcon
            style={{ color: "#39A1EC", fontSize: 30 }}
          />
        ),
        title: "Optimize for AEO",
        description: "Create a strategy to boost your company’s positioning",
        backgroundColor: "#DAEFFF",
        onClick: () => {},
        isReady: false,
      },
      {
        icon: (
          <LightbulbOutlinedIcon style={{ color: "#7D39EC", fontSize: 30 }} />
        ),
        title: "Create ads",
        description: "Design ads to boost your company’s branding",
        backgroundColor: "#E3E4FC",
        onClick: () => {},
        isReady: false,
      },
    ],
    [setOpenBlogDrawer]
  );

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        const workspaceData = response.data;
        localStorage.setItem("workspace", JSON.stringify(workspaceData));
        const iscomplete =
          workspaceData.business_step_completed &&
          workspaceData.persona_step_completed &&
          workspaceData.brand_voice_step_completed &&
          workspaceData.competitors_step_completed &&
          workspaceData.global_step_completed;

        setIsDnaCompleted(iscomplete);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
        setLoading(false);
      });
  }, [brandId]);

  const initialValues = useMemo(
    () => ({
      // business
      name: companyName || "",
      description: "",
      conversion_goals: [],
      industry: "",
      business_model: "",
      features: ["", ""],
      benefits: ["", ""],
      market_focus: "",
      usp: "",
      country: "",
      primary_language: "",
      website: "",
      secondary_countries: [],

      // persona
      age_ranges: [],
      genders: [],
      interests: [],
      job_functions: [],
      job_titles: [],
      desires: ["", ""],
      pain_points: ["", ""],

      // brand voice
      brand_voice: "",
      not_brand_voice: "",
      formality_tone: "",
      core_themes: [],
      emotions: [],
      styles: [],

      // competitors
      competitors: [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId]
  );

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values)
        .then((response) => {
          setLoading(false);
          window.location.href = `/dashboard/${brandId}/`;
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId]
  );

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return <Business brandDetails={{}} />;
      case 1:
        return <Persona brandDetails={{}} />;

      case 2:
        return <BrandVoice brandDetails={{}} />;
      case 3:
        return <Global brandDetails={{}} />;
      case 4:
        return <Competition brandDetails={{}} />;

      default:
        return <Business brandDetails={{}} />;
    }
  }, []);

  useEffect(() => {
    if (!isDnaCompleted && !loading) setOpenDNAModal(true);
    else setOpenDNAModal(false);
  }, [isDnaCompleted, loading]);

  return loading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "60%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          width: 20,
          height: 20,
          color: "#2D333A",
        }}
      />
    </div>
  ) : (
    <PageTemplate>
      <Typography
        style={{
          fontSize: "37.01px",
          color: "#141414",
          fontWeight: 500,
          margin: 0,
          padding: 0,
          lineHeight: 1,
        }}
      >
        Hey I’m Goodie.
      </Typography>
      <Typography
        style={{
          color: "#797979",
          fontSize: "37.01px",
          fontWeight: 500,
          marginBottom: 50,
          margin: 0,
          padding: 0,
        }}
      >
        Your supercharged ai-powered marketing tool.
      </Typography>

      {!isDnaCompleted && <IncompleteDna />}
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        sx={{ marginTop: 3 }}
      >
        {shortcuts.map((card, index) => {
          return (
            <Grid item xs={8} sm={4} md={4} key={index}>
              <ShortcutCard card={card} disabled={!isDnaCompleted} />
            </Grid>
          );
        })}
      </Grid>
      <RecentSection />
      <GenerateBlogModal
        openBlogDrawer={openBlogDrawer}
        setOpenBlogDrawer={setOpenBlogDrawer}
        brandId={brandId}
      />
      <StepperModal
        open={openDNAModal}
        handleClose={() => setOpenDNAModal(false)}
        setOpenModal={setOpenDNAModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={0}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
      />
    </PageTemplate>
  );
};
export default Dashboard;
