import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "../../../customIcons/CheckedIcon";

const SingleCheckBox = ({ name, header, subHeader, disabled }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Box
      sx={{
        padding: "10px 15px",
        border: `1px solid ${
          values[name] ? "#000000" : "#BAC1C980"
        } !important`,
        borderRadius: "8px",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={values[name]}
            onChange={(event) => setFieldValue(name, event.target.checked)}
            disabled={disabled}
            icon={<CheckBoxOutlineBlankIcon sx={{ color: "#BAC1C980" }} />}
            checkedIcon={<CheckedIcon />}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          />
        }
        label={
          <Typography
            style={{ fontSize: 14, color: disabled ? "#797979" : "#000000" }}
          >
            {header}{" "}
            <span style={{ color: disabled ? "#BAC1C9" : "#4D4D4D" }}>
              {subHeader}
            </span>
          </Typography>
        }
      />
    </Box>
  );
};
export default SingleCheckBox;
