import React from "react";
import {
  Radar,
  RadarChart as RechartsRadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";
import CustomeTooltip from "../LineChart/Components/Tooltip/Tooltip";

const colors = ["#718CF9", "#FF9933", "#FFD633", "#33D6D6"];
const llmColors = ["#C7F656", "#99DEE8", "#D0BEDB"];

const RadarChart = ({
  data,
  hasLegend,
  angleDataKey = "benchmark",
  radarTitles,
  hasTooltip = true,
  hasPolarAngleAxis = true,
  hasRaduisAxis,
  isLLM,
  colorMap = {},
}) => {
  return (
    <RechartsRadarChart
      cx={240}
      cy={190}
      outerRadius={150}
      width={500}
      height={400}
      data={data}
    >
      <PolarGrid />
      {hasPolarAngleAxis && (
        <PolarAngleAxis
          dataKey={angleDataKey}
          style={{ color: "#8C8C8C", fontSize: 12, fontWeight: 450 }}
        />
      )}
      {hasRaduisAxis && (
        <PolarRadiusAxis
          angle={270}
          domain={[0, 150]}
          style={{ color: "#8C8C8C", fontSize: 12, fontWeight: 450 }}
        />
      )}

      {radarTitles?.map((title, index) => (
        <Radar
          name={title}
          dataKey={title}
          stroke={
            isLLM
              ? llmColors[index]
              : Object.keys(colorMap).length > 0
              ? colorMap[title]
              : colors[index]
          }
          fill={
            isLLM
              ? llmColors[index]
              : Object.keys(colorMap).length > 0
              ? colorMap[title]
              : colors[index]
          }
          fillOpacity={0.1}
          strokeWidth={2}
        />
      ))}
      {hasTooltip && (
        <Tooltip
          content={<CustomeTooltip />}
          cursor={{
            stroke: "#CCCCCC",
            strokeWidth: 1,
            strokeDasharray: "2 2",
          }}
        />
      )}
      {hasLegend && <Legend />}
    </RechartsRadarChart>
  );
};
export default RadarChart;
