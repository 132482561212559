import { Grid } from "@mui/material";
import ChartCard from "../../components/ChartCard/ChartCard";
import PageLayout from "../../components/PageLayout/PageLayout";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LLMRankingTable from "../../components/LLMRankingTable/LLMRankingTable";
import RankingProgressCard from "../../components/RankingProgressCard/RankingProgressCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import GlobalIcon from "../../../../CommonComponents/customIcons/GlobalIcon";

const PerformanceAnalysis = () => {
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Performance Analysis"
        description="Gain insights into brand ranking across models, regions, and languages. Track ranking shifts over time, focusing on high-intent prompts, fact-checking, and hallucination monitoring."
        hasGlobalFilters={false}
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6.8}>
            <RankingProgressCard
              icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
              title="Competitive Ranking"
              tooltip="Displays your brand’s performance ranking compared to competitors. The data can be filtered for a preferred LLM. A higher score indicates stronger brand visibility across selected LLMs. All score are out of 100"
              height={266}
              hasTypographyRanking="1st"
              gridTitle2="Company"
              hasLLMSelect
              isPerformance
            />
          </Grid>
          <Grid item xs={5}>
            <RankingWheelsCard
              icon={<VisibilityIcon color="#000000" />}
              title="Ranking By Model"
              tooltip="Shows brand performance ranking by individual LLM models—ChatGPT, Gemini, and Perplexity. Higher scores indicate greater brand prominence within that model. All scores are out of 100"
              height={266}
              wheelsGrid={4}
              isPerformance
            />
          </Grid>{" "}
        </Grid>

        <LineChartCard
          icon={<VisibilityIcon color="#000000" />}
          title="Brand Ranking Over Time"
          tooltip="Track your brand’s performance over selected time intervals across specific LLM platforms like ChatGPT, Gemini, and Perplexity. Helps identify visibility trend over a period of time."
          hasCaledarSelect
          hasLLMselect
          height={328}
          chartHasXasix
          // chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
        />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <ChartCard
              icon={<GlobalIcon color="#000000" />}
              title="Ranking by Geography"
              tooltipMessage="Shows your brand’s visibility rank across different regions and LLMs, compared to competitors. Rank 1 indicates the highest visibility."
              height={254}
            >
              <LLMRankingTable rankingBy="Region" />
            </ChartCard>
          </Grid>
          <Grid item xs={5.85}>
            {/* <ChartCard
              icon={<SentimentIcon color="#000000" />}
              title="Ranking by Language"
              tooltipMessage="Shows your brand’s visibility rank across different languages and LLMs, compared to competitors. Rank 1 indicates the highest visibility."
              height={254}
            >
              <LLMRankingTable />
            </ChartCard> */}
          </Grid>{" "}
        </Grid>
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default PerformanceAnalysis;
