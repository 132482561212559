import { Outlet, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Header from "../Header";
import { Box } from "@mui/material";
import SideBar from "../SideBar";

const Layout = () => {
  const navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  const handleSidebarCollapseChange = (collapsed) => {
    setIsSidebarCollapsed(collapsed); // Update the state when Sidebar informs about collapse state change
  };
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F5F5F7",
        overflowX: "hidden",
      }}
    >
      <SideBar onCollapseChange={handleSidebarCollapseChange} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Header isCollapsed={isSidebarCollapsed} />
        <Box sx={{ mt: { xs: 5, md: 14 }, mr: "auto", ml: "auto" }}>
          <Outlet />
        </Box>
      </div>
    </Box>
  );
};

export default Layout;
