import { Avatar, Box, Card, Typography } from "@mui/material";
import Tag from "../RecentSection/components/Tag/Tag";
import Doc from "../../../../CommonComponents/customIcons/doc";
import { useNavigate, useParams } from "react-router-dom";
import { useBlog } from "../../../../contexts/blogContext";

const RecentsCard = ({ row }) => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { setEditedBlog: setEditedBlogContext } = useBlog();

  return (
    <Card
      sx={{
        borderRadius: "12px !important",
        padding: "20px 8px 28px 18px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        boxShadow: "none !important",
        rowGap: 4,
        minHeight: 180,
        border: "1px solid #fff",
        "&:hover": {
          backgroundColor: "#F5F5F5",
          border: "1px solid rgba(186, 193, 201, 0.50)",
        },
      }}
      onClick={() => {
        setEditedBlogContext(row.content);
        navigate(`/dashboard/${brandId}/blog/${row.title}`);
      }}
    >
      <Box style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tag>
            <Doc color="#003310"></Doc> {row.type}
          </Tag>
        </div>
        <Typography
          variant="h3"
          style={{ fontSize: 22, fontWeight: 450, maxWidth: 433 }}
        >
          {row.title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#141414",
          fontSize: "14.805px",
          fontWeight: 400,
          lineHeight: "18.506px",
          textAlign: "left",
        }}
      >
        <Avatar
          sx={{
            width: 20,
            height: 20,
            marginRight: "10px",
            backgroundColor: "#E7E7E7",
            color: "#797979",
            textAlign: "center",
            fontSize: "11.537px",
            fontWeight: 400,
          }}
        >
          {row.user.first_name
            ? row.user.first_name.charAt(0)
            : row.user.email.charAt(0)}
        </Avatar>
        <Typography noWrap sx={{ maxWidth: "85px" }}>
          {row.user.first_name && row.user.last_name
            ? `${row.user.first_name} ${row.user.last_name.charAt(0)}.`
            : row.user.email}
        </Typography>
      </Box>
    </Card>
  );
};
export default RecentsCard;
