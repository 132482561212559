import { Card, Typography } from "@mui/material";

const BlogWriterCard = ({
  title,
  children,
  width,
  centerContent,
  required,
}) => {
  return (
    <Card
      style={{
        padding: "0px 85px 70px 85px",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        width: width,
        paddingTop: centerContent ? 200 : 20,
      }}
    >
      <Typography style={{ fontSize: 40, fontWeight: 500 }}>
        {title} {required && <span style={{ color: "#718CF9" }}>*</span>}
      </Typography>
      {children}
    </Card>
  );
};
export default BlogWriterCard;
