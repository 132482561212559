import { Grid, Typography } from "@mui/material";

const TitlesGrid = ({
  title2,
  title3 = "Performance",
  grid1 = 1.5,
  grid3 = 4,
}) => {
  return (
    <Grid container>
      <Grid item xs={grid1}>
        <Typography
          style={{ color: "#8C8C8C", fontWeeight: 400, fontSize: 12 }}
        >
          #
        </Typography>
      </Grid>
      <Grid item xs={6.5}>
        <Typography
          style={{ color: "#8C8C8C", fontWeeight: 400, fontSize: 12 }}
        >
          {title2}
        </Typography>
      </Grid>
      <Grid
        item
        xs={grid3}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Typography
          style={{ color: "#666666", fontWeeight: 400, fontSize: 12 }}
        >
          {title3}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default TitlesGrid;
