import { Button, Card, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import ChipButton from "../../../../../../CommonComponents/ChipButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const WorkspaceSetUpCard = ({
  title,
  children,
  rowGap = 20,
  handleNext,
  handleBack,
  nextButtonLabel = "Continue",
  buttonFullWidth = true,
  nextButton = true,
  backButton = true,
  hasClose = false,
  handleClose,
  secondaryButtonLabel,
  handleSecondaryButton,
}) => {
  return (
    <Card
      style={{
        borderRadius: "35px",
        padding: "50px 85px 70px 85px",
        boxShadow: "0px 18.75px 50px 0px rgba(0, 0, 0, 0.12)",
        display: "flex",
        flexDirection: "column",
        width: 660,
        rowGap: rowGap,
        position: "relative",
      }}
    >
      {hasClose && (
        <Button
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#000",
            fontSize: "20px",
          }}
        >
          <CloseOutlinedIcon />
        </Button>
      )}
      {backButton && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ChipButton
            iconBefore={<WestIcon />}
            label="Back"
            backgroundColor="#F5F5F5"
            textColor="#2D333A"
            hoverBackgroundColor="#E1E1E1"
            hoverTextColor="#2D333A"
            hoverBorderColor="#E1E1E1"
            onClick={() => handleBack()}
            padding="6px 15px"
          />{" "}
        </div>
      )}
      <Typography
        style={{
          fontSize: 40,
          color: "#2D333A",
          fontWeight: 500,
          lineHeight: 1,
          padding: 0,
          margin: 0,
        }}
        variant="h2"
      >
        {title}
      </Typography>
      {children}
      {nextButton && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: 10,
          }}
        >
          <ChipButton
            iconAfter={<EastIcon />}
            label={nextButtonLabel}
            backgroundColor="#C7F565"
            textColor="#2D333A"
            hoverBackgroundColor="#EBFFC1"
            hoverTextColor="#2D333A"
            hoverBorderColor="#EBFFC1"
            fullWidth={buttonFullWidth}
            onClick={() => handleNext()}
          />
          {secondaryButtonLabel && (
            <span
              style={{ color: "#797979", fontSize: "14px", cursor: "pointer" }}
              onClick={handleSecondaryButton}
            >
              {secondaryButtonLabel}
            </span>
          )}
        </div>
      )}
    </Card>
  );
};
export default WorkspaceSetUpCard;
