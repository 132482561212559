import { Button } from "@mui/material";

const ChipButton = ({
  label,
  iconBefore,
  iconAfter,
  onClick,
  backgroundColor,
  textColor,
  borderColor,
  hoverBackgroundColor,
  hoverTextColor,
  hoverBorderColor,
  padding = "10px 20px",
  fontSize = "16px",
  fontWeight = 600,
  columnGap = 1,
  fullWidth = false,
  disabled,
}) => {
  return (
    <Button
      sx={{
        backgroundColor: disabled ? "#EBFFC1" : backgroundColor,
        color: textColor,
        padding: padding,
        fontSize: fontSize,
        borderRadius: "25px",
        height: "auto",
        border: `1px solid ${
          disabled ? "#EBFFC1" : borderColor ? borderColor : backgroundColor
        }`,
        fontWeight: fontWeight,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textTransform: "none",
        columnGap: columnGap,
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
          color: hoverTextColor,
          borderColor: hoverBorderColor,
        },
      }}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {iconBefore}
      {label}
      {iconAfter}
    </Button>
  );
};
export default ChipButton;
