import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

const SearchInput = ({
  searchQuery,
  setSearchQuery,
  placeholder = "Search files...",
  marginTop = 0,
}) => (
  <TextField
    variant="outlined"
    placeholder={placeholder}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    sx={{
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "11px",
      width: "388px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "11px",
        "& fieldset": {
          border: "none",
        },
        "&:hover fieldset": {
          border: "none",
        },
        "&.Mui-focused fieldset": {
          border: "none",
        },
      },
      "& .MuiInputAdornment-root": {
        color: "#000",
      },
      "& input": {
        padding: "12px 16px",
      },
      marginTop: { marginTop },
    }}
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
  />
);
export default SearchInput;
