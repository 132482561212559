import React, { createContext, useContext, useState } from "react";

const GlobalFiltersContext = createContext();

export const useGlobalFilters = () => useContext(GlobalFiltersContext);

export const GlobalFiltersProvider = ({ children }) => {
  const [date, setDate] = useState([]);
  const [llm, setLlm] = useState(["ChatGpt"]);
  const [initialDate, setInitialDate] = useState([]);
  const [initialCountries, setInitialCountries] = useState([]);
  const [noData, setNoData] = useState([]);

  return (
    <GlobalFiltersContext.Provider
      value={{
        date,
        setDate,
        llm,
        setLlm,
        initialDate,
        setInitialDate,
        initialCountries,
        setInitialCountries,
        noData,
        setNoData,
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};
