import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api";

const Accounts = () => {
  const { userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const brandId = queryParams.get("brandId");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    localStorage.setItem("userId", userId);
    brandId
      ? axios
          .get(`${API_URL}/accounts/${userId}`)
          .then((response) => {
            const userData = response.data;

            localStorage.setItem("loggedInUser", JSON.stringify(userData));
            localStorage.setItem("brandId", brandId);

            navigate(`/dashboard/${brandId}/`);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            setError("Failed to fetch user data");
            setLoading(false);
          })
      : navigate("/workspace-setup");
  }, [userId, brandId]);
  return;
};
export default Accounts;
