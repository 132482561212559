import { Box, Typography } from "@mui/material";

const InputSection = ({
  title,
  description,
  children,
  rowGap = 20,
  width = 830,
  required,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: rowGap,
        width: width,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
        <Typography
          variant="h4"
          component="h4"
          style={{
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {title} {required && <span style={{ color: "#718CF9" }}>*</span>}
        </Typography>
        {description && (
          <Typography
            style={{
              color: "#4D4D4D",
              fontSize: "14px",
              fontWeight: 450,
              whiteSpace: "pre-wrap",
              textAlign: "left",
              overflowWrap: "break-word",
              padding: 0,
              margin: 0,
            }}
          >
            {description}
          </Typography>
        )}
      </div>
      {children}
    </Box>
  );
};
export default InputSection;
