import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL, ML_API_URL } from "../../../../api";
import FormField from "../../../../CommonComponents/FormField";
import BlogWriterCard from "../Card/Card";

const Topic = ({
  nextFunction,
  setReferences,
  setBrandDetails,
  setLoadingAnimation,
  setLoadingAnimationText,
}) => {
  const { values } = useFormikContext();
  const { brandId } = useParams();

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}`)
      .then((response) => {
        setBrandDetails(response.data);
      })
      .catch((err) => {});
  }, [brandId]);

  const handleNext = useCallback(
    (step, setNextStep) => {
      setLoadingAnimation(true);
      setLoadingAnimationText("Finding relevant industry references");

      axios
        .post(`${ML_API_URL}/createai/generaterefernces`, values)
        .then((response) => {
          setReferences(response.data.references);
          setLoadingAnimation(false);
          setNextStep(step + 1);
        })
        .catch((err) => {
          setLoadingAnimation(false);
        });
    },
    [values]
  );

  useEffect(() => {
    nextFunction(handleNext);
  }, [nextFunction, values]);
  return (
    <BlogWriterCard
      title="Enter your blog topic"
      handleNext={() => handleNext()}
      width={900}
      marginTop={60}
      centerContent
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 20,
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography
          style={{ fontSize: 14, color: "#797979", fontWeight: 450 }}
          variant="body1"
        >
          Supply a topic relevant to your audience and industry.
        </Typography>
        <Box style={{ display: "flex", flexDirection: "column", rowGap: 8 }}>
          <FormField
            type="input"
            name="topic"
            placeholder="Top 10 Tips for E-commerce Success"
            customizedStyling={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& fieldset": {
                borderWidth: "1px",
                borderColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          />
        </Box>
      </Box>
    </BlogWriterCard>
  );
};
export default Topic;
