import { Card, Typography, Box } from "@mui/material";
import BoxWithStatus from "../../../../CommonComponents/BoxWithStatus";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";

const ComingSoon = ({ children }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {children}
      <Typography
        sx={{
          fontSize: "12.95px",
          fontWeight: 500,
          borderRadius: "28.68px",
          color: "#2D333A",
          mb: 1,
        }}
      >
        Coming Soon
      </Typography>
    </Box>
  );
};
const AdmissAccessRequired = ({ children }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {children}
      <Typography
        sx={{
          backgroundColor: "#F4F4F5",
          color: "#797979",
          padding: "2px 12px",
          borderRadius: "25px",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          columnGap: 1,
        }}
      >
        <LockOutlinedIcon style={{ fontSize: 15 }} />
        Admin access only
      </Typography>
    </Box>
  );
};

const WorkSpaceCard = ({ card }) => {
  const {
    icon,
    title,
    description,
    isIncomplete,
    isReady,
    requiresAdminAccess,
    adminAccess,
    navigateTo,
  } = card;

  const renderStatus = () => {
    if (!isReady) {
      return <ComingSoon>{icon}</ComingSoon>;
    } else if (requiresAdminAccess && !adminAccess) {
      return <AdmissAccessRequired>{icon}</AdmissAccessRequired>;
    } else if (isIncomplete) {
      return <BoxWithStatus>{icon}</BoxWithStatus>;
    }

    return icon;
  };
  const navigate = useNavigate();

  const disabled = !isReady || (requiresAdminAccess && !adminAccess);
  return (
    <Card
      style={{
        borderRadius: "12px",
        padding: "20px 8px 12px 16px",
        boxShadow: "0px 18.75px 50px 0px rgba(0, 0, 0, 0.12)",
        display: "flex",
        flexDirection: "column",
        cursor: disabled ? "not-allowed" : "pointer",
        border: "1px solid #fff",
        position: "relative",
      }}
      sx={{
        "&:hover": {
          backgroundColor: disabled ? "inherit" : "#F5F5F5",
          border: disabled
            ? "1px solid rgba(186, 193, 201, 0.12)"
            : "1px solid rgba(186, 193, 201, 0.50) !important",
        },
      }}
      onClick={() => (!disabled ? navigate(navigateTo) : undefined)}
    >
      {disabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 2,
            borderRadius: "12px",
          }}
        />
      )}
      {renderStatus()}
      <Typography
        variant="body"
        sx={{ fontSize: 14, color: "#2D333A", mt: { xs: 1, md: 4 } }}
      >
        {title}
      </Typography>
      <Typography style={{ fontSize: 14, color: "#797979" }}>
        {description}
      </Typography>
    </Card>
  );
};
export default WorkSpaceCard;
