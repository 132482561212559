import { MenuItem, Select } from "@mui/material";

const RolesDropdown = ({ dropdownValue, handleDropdownChange }) => {
  return (
    <Select
      variant="standard"
      disableUnderline
      value={dropdownValue}
      onChange={handleDropdownChange}
      sx={{
        padding: "0",
        "& .MuiSelect-select": {
          padding: "0 8px",
          border: "none",
          backgroundColor: "transparent",
          fontSize: "14px",
          color: "#797979",
        },
      }}
    >
      <MenuItem value="admin" sx={{ fontSize: "14px", color: "#797979" }}>
        Admin
      </MenuItem>
      <MenuItem value="author" sx={{ fontSize: "14px", color: "#797979" }}>
        Author
      </MenuItem>
    </Select>
  );
};
export default RolesDropdown;
