import { TextField } from "@mui/material";
import { Field } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../ErrorMessage";

const FormikTextField = ({
  name,
  placeholder,
  startAdornment,
  endAdornment,
  disabled,
  ispassword,
  margin = true,
  width,
  errorMessage,
}) => {
  return (
    <>
      <Field
        name={name}
        as={TextField}
        variant="outlined"
        margin={margin ? "normal" : "none"}
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        type={ispassword ? "password" : "text"}
        sx={{
          width: width,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "1px solid rgba(0, 0, 0, 0.10)",
            },
            "&:hover fieldset": {
              border: "1px solid",
              borderColor: "#BAC1C9",
            },
            "&.Mui-focused fieldset": {
              border: "1px solid",
              borderColor: "#BAC1C9",
            },
          },
        }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment
              position="end"
              sx={{
                pointerEvents: "auto",
                cursor: "pointer",
              }}
            >
              {endAdornment}
            </InputAdornment>
          ),
        }}
      />
      {errorMessage && <ErrorMessage name={name} />}
    </>
  );
};
export default FormikTextField;
