import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import PageTemplate from "../../../../CommonComponents/PageTemplate/PageTemplate";
import DateFilter from "../DateFilter/DateFilter";
import LLMDropDown from "../LLMDropdown/LLMDropdown";
import { API_URL, ML_AEO_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import CountriesDropdown from "../CountriesUpdateDropdown";
import IncompleteDna from "../../../Dashboard/components/IncompleteDna/IncompleteDna";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import StepperModal from "../../../../CommonComponents/StepperModal";
import Business from "../../../BrandDna/components/BrandDNAForm/components/Business";
import Persona from "../../../BrandDna/components/BrandDNAForm/components/Persona";
import BrandVoice from "../../../BrandDna/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Competition from "../../../BrandDna/components/BrandDNAForm/components/Competition/Competition";
import { getValidationSchema, steps } from "../../../BrandDna/conts";
import Global from "../../../BrandDna/components/BrandDNAForm/components/Global";

const PageLayout = ({
  title,
  description,
  hasGlobalFilters = true,
  hasCountriesSeelect,
  isGlobal,
  children,
}) => {
  const { brandId } = useParams();
  const {
    date,
    setDate,
    llm,
    setLlm,
    setInitialDate,
    setNoData: setGlobalNoData,
  } = useGlobalFilters();
  const [availabledates, setAvailableDates] = useState({});
  const [isDnaCompleted, setIsDnaCompleted] = useState(true);
  const [noData, setNoData] = useState(false);
  const [countriesChange, setCountriesChange] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [brandDetails, setBrandDetails] = useState({});
  const companyName = localStorage.getItem("worskapceName") || "Workspace name";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .post(`${ML_AEO_URL}/aeo/availabledates`, { brandId })
      .then((response) => {
        const data = response.data[0];
        setAvailableDates(data);
        setDate([dayjs(data.start_date), dayjs(data.end_date)]);
        setInitialDate([dayjs(data.start_date), dayjs(data.end_date)]);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (
            error.response.data.detail ===
            "Database error: 404: No evaluations found for the given brand ID"
          ) {
            setNoData(true);
          } else {
            console.log("An error occurred:", error);
          }
        } else {
          console.log("An unexpected error occurred:", error);
        }
      });
  }, [brandId, setDate, setInitialDate, setAvailableDates]);

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        const workspaceData = response.data;
        const iscomplete =
          workspaceData.business_step_completed &&
          workspaceData.persona_step_completed &&
          workspaceData.brand_voice_step_completed &&
          workspaceData.competitors_step_completed &&
          workspaceData.global_step_completed;

        setIsDnaCompleted(iscomplete);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [brandId]);
  useEffect(() => {
    setGlobalNoData(noData);
  }, [noData, setGlobalNoData]);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <Business brandDetails={brandDetails} />;
        case 1:
          return <Persona brandDetails={brandDetails} />;

        case 2:
          return <BrandVoice brandDetails={brandDetails} />;
        case 3:
          return <Global brandDetails={brandDetails} />;
        case 4:
          return <Competition brandDetails={brandDetails} />;

        default:
          return <Business brandDetails={brandDetails} />;
      }
    },
    [brandDetails]
  );

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}`)
      .then((response) => {
        setBrandDetails(response.data);
        localStorage.setItem("worskapceName", response.data.name);
      })
      .catch((err) => {});
  }, [brandId]);

  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      secondary_countries: brandDetails?.secondary_countries || [],

      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId, brandDetails]
  );
  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values, {})
        .then((response) => {
          setLoading(false);
          setOpenModal(false);
          setCountriesChange(true);
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId]
  );
  return (
    <PageTemplate>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 22,
          paddingBottom: 100,
        }}
      >
        <Typography
          style={{ fontWeight: 500, fontSize: 37.01, color: "#141414" }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: "#666666",
            maxWidth: 594,
          }}
        >
          {description}
        </Typography>
        {!isDnaCompleted ? (
          <IncompleteDna isAeo />
        ) : (
          noData && (
            <Typography
              sx={{
                backgroundColor: "#0099331A",
                padding: "5px 12px",
                fontSize: "14.8px",
                fontWeight: 500,
                borderRadius: "11px",
                color: "#009933",
                border: "0.5px solid #009933",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                columnGap: 1,
              }}
            >
              <CheckCircleOutlineRoundedIcon style={{ fontSize: 18 }} />
              You’re all set! We’re currently processing your data, which will
              be available for you to view within 48 hours. We’ll notify you by
              email once it is ready.
            </Typography>
          )
        )}
        {isGlobal && (
          <Box style={{ display: "flex", flexDirection: "row", columnGap: 10 }}>
            <Typography
              sx={{
                backgroundColor: "#E5E5E5",
                padding: "5px 12px",
                fontSize: "14px",
                fontWeight: 500,
                borderRadius: "11px",
                color: "#0D0D0D",
                border: "0.5px solid #E5E5E5",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                columnGap: 1,
                cursor: "pointer",
              }}
              onClick={() => setOpenModal(true)}
            >
              <AddCircleOutlineSharpIcon style={{ fontSize: 18 }} />
              Add another country
            </Typography>
            {countriesChange && (
              <Typography
                sx={{
                  backgroundColor: "#0099331A",
                  padding: "5px 12px",
                  fontSize: "14px",
                  fontWeight: 500,
                  borderRadius: "11px",
                  color: "#009933",
                  border: "0.5px solid #009933",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: 1,
                }}
              >
                <CheckCircleOutlineRoundedIcon style={{ fontSize: 18 }} />
                New change in Global Brand DNA: Changes to countries will appear
                after the next 12-hour analysis.
              </Typography>
            )}
          </Box>
        )}

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 9,
            marginTop: 40,
          }}
        >
          <Divider sx={{ borderColor: "#E1E1E1" }} />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid item>
              <Typography
                style={{ color: "#797979", fontSize: 14, fontWeight: 450 }}
              >
                Last update{" "}
                {availabledates.end_date &&
                  dayjs(availabledates.end_date).format(
                    "MMMM DD, YYYY @ h:mma"
                  )}
              </Typography>
            </Grid>
            {hasGlobalFilters && (
              <Grid
                item
                style={{ display: "flex", flexDirection: "row", columnGap: 4 }}
              >
                {hasCountriesSeelect && (
                  <CountriesDropdown setCountriesChange={setCountriesChange} />
                )}

                <DateFilter
                  backgroundColor="#FFFFFF"
                  startDate={availabledates.start_date}
                  endDate={availabledates.end_date}
                  setValue={setDate}
                  value={date}
                />
                <LLMDropDown
                  backgroundColor="#FFFFFF"
                  setSelectedLLM={setLlm}
                  selectedLLM={llm}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {children}
      </Box>
      <StepperModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={3}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
      />
    </PageTemplate>
  );
};
export default PageLayout;
