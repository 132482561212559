import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Paper, GlobalStyles } from "@mui/material";
import CustomToolbar from "./components/customToolbar";
import { marked } from "marked";

const TextEditor = ({ generatedOutline, setEditedBlog }) => {
  const [editorContent, setEditorContent] = useState(generatedOutline);

  useEffect(() => {
    const html = marked(generatedOutline);
    setEditorContent(html);
    setEditedBlog(html);
  }, [generatedOutline]);

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setEditedBlog(content);
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  return (
    <Box
      sx={{
        width: "700px",
        height: "auto",
        boxSizing: "border-box",
        borderRadius: "11px",
        border: "1px solid",
      }}
    >
      <GlobalStyles
        styles={{
          ".ql-toolbar.ql-snow": {
            border: "none",
            boxSizing: "border-box",
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            padding: "8px",
          },
        }}
      />
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          height: "auto",
          padding: "10px",
          boxSizing: "border-box",
          borderRadius: "11px",
          backgroundColor: "#F6F6F6",
          marginBottom: "20px",
          boxShadow: "none",
        }}
      >
        <CustomToolbar />
        <ReactQuill
          value={editorContent}
          onChange={handleEditorChange}
          modules={modules}
          theme="snow"
          style={{
            border: "none",
          }}
          editorProps={{
            style: {
              border: "none",
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default TextEditor;
