import { Box } from "@mui/material";

const PageTemplate = ({ children }) => {
  return (
    <Box
      sx={{
        pl: { xs: 1, md: 6 },
        pr: { xs: 1, md: 8 },
        width: "80vw",
      }}
    >
      {children}
    </Box>
  );
};
export default PageTemplate;
