import { Card, Typography } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";

const AccountsCard = ({ title, children, rowGap = 20 }) => {
  const initialValues = useMemo(() => ({}), []);
  return (
    <Formik initialValues={initialValues}>
      {({ values, errors, dirty }) => (
        <Card
          style={{
            borderRadius: "35px",
            padding: "50px 85px 70px 85px",
            boxShadow: "0px 18.75px 50px 0px rgba(0, 0, 0, 0.12)",
            display: "flex",
            flexDirection: "column",
            width: 660,
            rowGap: rowGap,
          }}
        >
          <Typography
            style={{ fontSize: 40, color: "#2D333A", fontWeight: 600 }}
          >
            {title}
          </Typography>
          {children}
        </Card>
      )}
    </Formik>
  );
};
export default AccountsCard;
