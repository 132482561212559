import { TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../../../../../api";
import WorkspaceSetUpCard from "../Card/Card";

const InviteUsers = ({
  setCurrentStep,
  brandName,
  hasClose,
  handleClose,
  backButton,
}) => {
  const [users, setUsers] = useState([]);
  const handleChange = (event) => {
    setUsers(event.target.value);
  };
  const brandId = localStorage.getItem("brandId");

  const handleInviteUsers = () => {
    const emails = users.split(",");
    const values = { emails: emails, isAdmin: false };
    axios
      .post(`${API_URL}/brands/${brandId}/users`, values)
      .then((response) => {
        setCurrentStep(6);
      })
      .catch((err) => {
        console.log("test error", err);
      });
  };
  return (
    <WorkspaceSetUpCard
      title={`Who else is on the ${brandName} team?`}
      handleBack={() => setCurrentStep(1)}
      handleNext={() => handleInviteUsers()}
      backButton={backButton}
      hasClose={hasClose}
      handleClose={handleClose}
      buttonFullWidth={false}
      secondaryButtonLabel="Skip this step"
      handleSecondaryButton={() => setCurrentStep(6)}
    >
      <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
        <Typography style={{ fontSize: 15 }}>Add coworkers by email</Typography>
        <TextField
          value={users}
          onChange={handleChange}
          placeholder="Ex: ellis@gmail.com, maria@gmail.com"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "1px solid rgba(0, 0, 0, 0.10)",
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#BAC1C9",
              },
            },
          }}
        />
      </div>
    </WorkspaceSetUpCard>
  );
};
export default InviteUsers;
